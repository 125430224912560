import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )

  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.NUMERIC
  )

  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MIN_LENGTH
  )
  
  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"

  return Yup.object({
    containerNumber: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(14,maxLengthErrorMessage + " 14").nullable(),
    sealNumber1: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12,maxLengthErrorMessage + " 12").nullable(),
    sealNumber2: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12,maxLengthErrorMessage + " 12").nullable(),
    containerDescriptionCode: Yup.string().nullable(),
    billCargoDesc: Yup.array(Yup.object({
      pieceCount: Yup.number().typeError(numericErrorMessage).required(requiredMessage).min(1,minLengthErrorMessage + " 1").nullable(),
      description: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(45,maxLengthErrorMessage + " 45").nullable(),
      manifestUnitCode: Yup.string().nullable(),
    })).nullable(),
    htsInformation: Yup.array(Yup.object({
      harmonizedNumber: Yup.number().typeError(numericErrorMessage).required(requiredMessage).min(1,minLengthErrorMessage + " 1").nullable(),
      value: Yup.number().typeError(numericErrorMessage).required(requiredMessage).min(1,minLengthErrorMessage + " 1").nullable(),
      weight: Yup.number().typeError(numericErrorMessage).required(requiredMessage).min(1,minLengthErrorMessage + " 1").nullable(),
      weightUnit: Yup.string().required(requiredMessage).nullable(),
    })).nullable(),
    marksAndNumbers: Yup.array(Yup.object({
      marksAndNumbers: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(45,maxLengthErrorMessage + " 45").nullable(),
    })).nullable()
  })
}

export default makeValidationSchema
