import React, { useRef } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import BolContainerFormProperties from './BolContainerFormProperties'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from 'src/components/cngcomponents/CngSubSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import { useFormContext } from 'react-hook-form'
import { Box, Divider, Grid, Typography } from '@material-ui/core'

const {
  button: { CngButton, CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function BolContainerDialog(props) {
  const {
    bolContainer,
    isView,
    onClose,
    open,
    showNotification
  } = props

  const { initialValues, makeValidationSchema } =
    BolContainerFormProperties.formikProps

  const lastIndex = useRef(isView ? null : 0)
  const formContext = useFormContext()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let bolContainer = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.TITLE
    )
    let containerNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_NUMBER
    )
    let sealNumber1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER1
    )
    let sealNumber2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER2
    )
    let containerDescriptionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_DESCRIPTION_CODE
    )
    let billCargoDesc = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.TITLE
    )
    let pieceCount = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.PIECE_COUNT
    )
    let description = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.DESCRIPTION
    )
    let manifestUnitCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.MANIFEST_UNIT_CODE
    )
    let htsInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.TITLE
    )
    let harmonizedNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.HARMONIZED_NUMBER
    )
    let htsValue = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.VALUE
    )
    let htsWeight = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.WEIGHT
    )
    let htsWeightUom = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.WEIGHT_UNIT
    )

    let hazmatInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.TITLE
    )
    let hazardousMaterialCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CODE
    )
    let hazardousMaterialClass = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CLASS
    )
    let hazardousMaterialCodeQual = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CODE_QUAL
    )
    let hazardousMaterialDescription = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_DESCRIPTION
    )
    let hazardousMaterialContact = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CONTACT
    )
    let flashpointTemperature = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.FLASHPOINT_TEMPERATURE
    )
    let uomCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.UOM_CODE
    )
    let negativeIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.NEGATIVE_INDICATOR
    )
    let hazardousMaterialDescription1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_DESCRIPTION1
    )
    let hazardousMaterialClassification1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CLASSIFICATION1
    )
    let hazardousMaterialDescription2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_DESCRIPTION2
    )
    let hazardousMaterialClassification2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CLASSIFICATION2
    )
    let marksAndNumbersTitle = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.MarksAndNumbers.TITLE
    )
    let marksAndNumbers = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.MarksAndNumbers.MARKS_AND_NUMBERS
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATE_BUTTON
    )

    return {
      bolContainer,
      containerNumber,
      sealNumber1,
      sealNumber2,
      containerDescriptionCode,
      billCargoDesc,
      pieceCount,
      description,
      manifestUnitCode,
      htsInformation,
      harmonizedNumber,
      htsValue,
      htsWeight,
      htsWeightUom,
      hazmatInformation,
      hazardousMaterialCode,
      hazardousMaterialClass,
      hazardousMaterialCodeQual,
      hazardousMaterialDescription,
      hazardousMaterialContact,
      flashpointTemperature,
      uomCode,
      negativeIndicator,
      hazardousMaterialDescription1,
      hazardousMaterialClassification1,
      hazardousMaterialDescription2,
      hazardousMaterialClassification2,
      marksAndNumbersTitle,
      marksAndNumbers,
      saveButton,
      updateButton
    }
  }

  function handleAddBolContainer(data) {
    const bolContainer = [...formContext.getValues('bolContainer'), data].map(
      (bolContainer) => ({
        ...bolContainer,
        _id: ++lastIndex.current
      })
    )

    formContext.setValue('bolContainer', bolContainer, { shouldValidate: true })
    onClose()
  }

  function handleEditBolContainer(data) {
    const bolContainer = [...formContext.getValues('bolContainer')]
    const index = bolContainer.findIndex((bolContainer) =>
      data.id ? bolContainer.id === data.id : bolContainer._id === data._id
    )

    formContext.setValue(`bolContainer.${index}`, data, {
      shouldValidate: true
    })
    onClose()
  }

  if (!open) {
    return null
  }

  let viewContent = bolContainer && (
    <CngSection title='Classification &amp; weight'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={4} />
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CngField label={translatedTextsObject.containerNumber}>
                    {bolContainer.containerNumber}
                  </CngField>
                </Grid>
                <Grid item xs={6}>
                  <CngField label={translatedTextsObject.sealNumber1}>
                    {bolContainer.sealNumber1}
                  </CngField>
                </Grid>
                <Grid item xs={6}>
                  <CngField label={translatedTextsObject.sealNumber2}>
                    {bolContainer.sealNumber2}
                  </CngField>
                </Grid>
                <Grid item xs={6}>
                  <CngField label={translatedTextsObject.containerDescriptionCode}>
                    {bolContainer.containerDescriptionCode}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CngSubSection title={translatedTextsObject.billCargoDesc}>
            {bolContainer.billCargoDesc.length > 0 ? (
              <Grid container spacing={2}>
                {bolContainer.billCargoDesc.map((cargoDesc, index) => (
                  <React.Fragment key={Math.random()}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.pieceCount}>
                            {cargoDesc.pieceCount}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.description}>
                            {cargoDesc.description}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.manifestUnitCode}>
                            {cargoDesc.manifestUnitCode}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {bolContainer.billCargoDesc.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSubSection>
        </Grid>
        <Grid item xs={12}>
          <CngSubSection title={translatedTextsObject.htsInformation}>
            {bolContainer.htsInformation.length > 0 ? (
              <Grid container spacing={2}>
                {bolContainer.htsInformation.map((hts, index) => (
                  <React.Fragment key={Math.random()}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.harmonizedNumber}>
                            {hts.harmonizedNumber}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.htsValue}>
                            {hts.value}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <CngField label={`${translatedTextsObject.htsWeight} & ${translatedTextsObject.htsWeightUom}`}>
                            <Typography component='div' variant='inherit'>
                              {hts.weight}
                              <TinyChip label={hts.weightUnit} />
                            </Typography>
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {bolContainer.htsInformation.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSubSection>
        </Grid>
        <Grid item xs={12}>
          <CngSubSection title={translatedTextsObject.marksAndNumbersTitle}>
            {bolContainer.marksAndNumbers.length > 0 ? (
              <Grid container spacing={2}>
                {bolContainer.marksAndNumbers.map((marks, index) => (
                  <React.Fragment key={Math.random()}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.marksAndNumbers}>
                            {marks.marksAndNumbers}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {bolContainer.marksAndNumbers.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSubSection>
        </Grid>
        <Grid item xs={12}>
          <CngSubSection title={translatedTextsObject.hazmatInformation}>
            {bolContainer.hazmatInformation.length > 0 ? (
              <Grid container spacing={2}>
                {bolContainer.hazmatInformation.map((hazmat, index) => (
                  <React.Fragment key={Math.random()}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialCode}>
                            {hazmat.hazardousMaterialCode}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialClass}>
                            {hazmat.hazardousMaterialClass}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialCodeQual}>
                            {hazmat.hazardousMaterialCodeQual}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialDescription}>
                            {hazmat.hazardousMaterialDescription}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialContact}>
                            {hazmat.hazardousMaterialContact}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <CngField label={`${translatedTextsObject.flashpointTemperature} & ${translatedTextsObject.uomCode}`}>
                            <Typography component='div' variant='inherit'>
                              {hazmat.negativeIndicator ? "-" : "+"}{hazmat.flashpointTemperature}
                              <TinyChip label={hazmat.uomCode} />
                            </Typography>
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialDescription1}>
                            {hazmat.hazardousMaterialDescription1}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialClassification1}>
                            {hazmat.hazardousMaterialClassification1}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialDescription2}>
                            {hazmat.hazardousMaterialDescription2}
                          </CngField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CngField label={translatedTextsObject.hazardousMaterialClassification2}>
                            {hazmat.hazardousMaterialClassification2}
                          </CngField>
                        </Grid>
                      </Grid>
                    </Grid>
                    {bolContainer.hazmatInformation.length !== index + 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Typography variant='body2' color='textSecondary'>
                No records to display
              </Typography>
            )}
          </CngSubSection>
        </Grid>
      </Grid>
    </CngSection>
  )

  return (
    <CngDialog
      dialogContent={
        bolContainer && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: bolContainer || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (bolContainer) {
                  handleEditBolContainer(data)
                } else {
                  handleAddBolContainer(data)
                }
              }
            }}
            formState={FormState.COMPLETED}
            innerForm={true}
            renderBodySection={(labelMap, shouldHideMap) => (
              <BolContainerFormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            )}
            renderButtonSection={() => (
              <Box display='flex' justifyContent='flex-end'>
                <CngPrimaryButton
                  type='submit'
                  disabled={
                    formContext ? formContext.formState.isSubmitting : false
                  }
                >
                  {bolContainer
                    ? translatedTextsObject.updateButton
                    : translatedTextsObject.saveButton}
                </CngPrimaryButton>
              </Box>
            )}
          />
        )
      }
      dialogTitle={translatedTextsObject.bolContainer}
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='md'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default BolContainerDialog
