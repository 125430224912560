import { Box, Divider, Grid, IconButton, InputAdornment, Typography, useTheme } from '@material-ui/core'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaAceHwyPOLPortAutocompleteField, NaAceStateCodeAutoCompleteField } from 'src/components/na'
import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'

import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import CngSection from 'src/components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { NaPartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import Namespace from 'src/constants/locale/Namespace'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import Switch from 'src/components/Switch'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import _ from 'lodash'
import makeValidationSchema from './PartiesMakeValidationSchema'

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngSelectField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    }
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: '',
  partyId: '',
  saveFlag: false,
  name: '',
  address1: '',
  address2: '',
  address3: '',
  telNo: '',
  faxNo:''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, reset, watch, trigger } = useFormContext()

  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [templateDialog, setTemplateDialog] = useState(false)
  const theme = useTheme()

  const saveFlag = watch('saveFlag')
  const partyType = watch('partyType')

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    Promise.all([
      // Crew Type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_PARTY_TYPE' }],
        undefined,
        'code'
      )
    ]).then(([partyType]) => {
      setLookups({
        partyType
      })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let parties = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.TITLE
    )
    let partyType = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.PARTY_TYPE
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.PARTY_ID
    )
    let name = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.NAME
    )
    let address1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.ADDRESS1
    )
    let address2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.ADDRESS2
    )
    let address3 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.ADDRESS3
    )
    let telNo = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.TELNO
    )
    let partyDropDown = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.PARTY_DROP_DOWN
    )
    let saveFlag = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.SAVE_FLAG
    )
    let faxNo = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.FAXNO
    )

    return {
      parties,
      partyType,
      partyId,
      name,
      address1,
      address2,
      address3,
      telNo,
      partyDropDown,
      saveFlag,
      faxNo
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: 'partytype',
      title: translatedTextsObject.partyType,
      render: (data) =>
        <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
          {getLookupValue('partyType', data.partytype)}
        </Typography>
    },
    {
      field: 'partyidn',
      title: translatedTextsObject.partyId
    },
    {
      field: 'partyname1',
      title: translatedTextsObject.name
    }
  ]

  const sortOptions = [
    {
      label: translatedTextsObject.partyId,
      value: 'partyidn'
    },
    {
      label: translatedTextsObject.partyType,
      value: 'partytype'
    }
  ]

  function handleApplyTemplate(template) {
    console.log("tem"+JSON.stringify(template))
    if (template) {
      const {
        partyidn,
        partytype,
        partyname1,
        address1,
        address2,
        address3,
        telNo,
        faxNo
      } = template

      const partyType = _.isEmpty(partytype) ? '' : partytype.slice(-2)

      // const convertPartyType = [{id: "OS", value:"CZ"},{id: "OS", value:"SH"}]

      // var matchPartyObj = (convertPartyType.find(obj=>{
      //   return obj.id === partyType || obj.value === partyType;
      // }))
      // var convPartyType = partyType ? undefined !== matchPartyObj ? matchPartyObj.id : partyType : partyType;
 
      setValue('partyId', partyidn, { shouldDirty: true })
      setValue('partyType', partyType, { shouldDirty: true })
      setValue('name', partyname1 || '', { shouldDirty: true })
      setValue('address1', address1 || '', { shouldDirty: true })
      setValue('address2', address2 || '', { shouldDirty: true })
      setValue('address3', address3 || '', { shouldDirty: true })
      setValue('telNo', telNo || '', { shouldDirty: true })
      setValue('faxNo', faxNo || '', { shouldDirty: true })
      
      trigger()
    } else {
      reset()
    }
  }

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2}>
          <CngGridItem xs={12}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} lg={4} shouldHide={shouldHideMap?.partyType}>
                <CngSelectField
                  required
                  size='small'
                  name="partyType"
                  label={translatedTextsObject.partyType}
                  disabled={disabled}
                  items={[{ text: "Consignee", value: "CN" },{ text: "Notify", value: "NP" },{ text: "Shipper", value: "SI" }]}
                />
              </CngGridItem>
              <CngGridItem xs={12} lg={8}>
                <Grid container spacing={1}>
                  <CngGridItem
                    xs={12}
                    sm={3}
                    shouldHide={shouldHideMap?.saveFlag}
                  >
                    <CngCheckboxField
                      label={
                        <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.saveFlag}
                        </Typography>
                      }
                      name='saveFlag'
                      onChange={(e) =>
                        setValue('saveFlag', e.target.checked, {
                          shouldValidate: true
                        })
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    sm
                    shouldHide={shouldHideMap?.partyId}
                  >
                    <CngTextField
                      required
                      name='partyId'
                      inputProps={{ maxLength: 35 }}
                      placeholder={translatedTextsObject.partyId}
                      disabled={disabled || !saveFlag}
                      onChange={(e) => {
                        setValue(
                          'partyId',
                          e.target.value.toUpperCase(),
                          { shouldValidate: true }
                        )
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('partyId', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm='auto'>
                    <Divider orientation='vertical' />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={4}>
                    <NaAcePartyMasterAutoCompleteField
                      name="partyDropDown"
                      label={translatedTextsObject.partyDropDown}
                      disabled={disabled}
                      onChange={(_, options) => handleApplyTemplate(options.data)}
                      lookupProps= {{
                        filters: [{
                          field: 'partyId',
                          operator: EQUAL,
                          value: FileForUserGetPartyId()
                        },
                        { 
                          field: 'partytype', 
                          operator: IN, 
                          value: ['CN', 'NI', 'SH'] 
                        }]
                      }} 
                      size='small'
                      fullWidth
                      disableClearable
                      textFieldProps={{
                        InputProps: {
                          customEndAdornment: () => (
                            <InputAdornment position='end' style={{ marginTop: -16 }}>
                              <IconButton onClick={(event) => {
                                event.stopPropagation()
                                setTemplateDialog(true)
                              }}>
                                <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                  <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      }}
                      forcePopupIcon={false}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
              
              <CngGridItem xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                    shouldHide={shouldHideMap?.name}
                  >
                    <CngTextField
                      required
                      name='name'
                      label={translatedTextsObject.name}
                      disabled={disabled}
                      onChange={(e) => {
                        setValue('name', e.target.value.toUpperCase(), {
                          shouldValidate: true
                        })
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('name', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                      inputProps={{ maxLength: 35 }}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                    shouldHide={shouldHideMap?.address1}
                  >
                    <CngTextField
                      required
                      name='address1'
                      label={translatedTextsObject.address1}
                      disabled={disabled}
                      onChange={(e) => {
                        setValue(
                          'address1',
                          e.target.value.toUpperCase(),
                          { shouldValidate: true }
                        )
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('address1', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                      inputProps={{ maxLength: 35 }}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                    shouldHide={shouldHideMap?.address2}
                  >
                    <CngTextField
                      name='address2'
                      label={translatedTextsObject.address2}
                      disabled={disabled}
                      onChange={(e) => {
                        setValue(
                          'address2',
                          e.target.value.toUpperCase(),
                          { shouldValidate: true }
                        )
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('address2', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                      inputProps={{ maxLength: 35 }}
                    />
                  </CngGridItem>
                  <CngGridItem
                    xs={12}
                    md={6}
                    lg={4}
                    shouldHide={shouldHideMap?.address3}
                  >
                    <CngTextField
                      name='address3'
                      label={translatedTextsObject.address3}
                      disabled={disabled}
                      onChange={(e) => {
                        setValue(
                          'address3',
                          e.target.value.toUpperCase(),
                          {
                            shouldValidate: true
                          }
                        )
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== undefined) {
                          setValue('address3', e.target.value.trim(), {
                            shouldValidate: true
                          })
                        }
                      }}
                      size='small'
                      inputProps={{ maxLength: 35 }}
                    />
                  </CngGridItem>
                  <CngGridItem
                      xs={12}
                      md={4}
                      shouldHide={shouldHideMap?.telNo}
                    >
                      <CngTextField
                        name='telNo'
                        label={translatedTextsObject.telNo}
                        disabled={disabled}
                        onChange={(e) => {
                          setValue('telNo', e.target.value.toUpperCase(), {
                            shouldValidate: true
                          })
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== undefined) {
                            setValue('telNo', e.target.value.trim(), {
                              shouldValidate: true
                            })
                          }
                        }}
                        size='small'
                        inputProps={{ maxLength: 35 }}
                      />
                    </CngGridItem>
                    <CngGridItem
                      xs={12}
                      md={4}
                      shouldHide={shouldHideMap?.faxNo}
                    >
                      <CngTextField
                        name='faxNo'
                        label={translatedTextsObject.faxNo}
                        disabled={disabled}
                        onChange={(e) => {
                          setValue('faxNo', e.target.value.toUpperCase(), {
                            shouldValidate: true
                          })
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== undefined) {
                            setValue('faxNo', e.target.value.trim(), {
                              shouldValidate: true
                            })
                          }
                        }}
                        size='small'
                        inputProps={{ maxLength: 30 }}
                      />
                    </CngGridItem>
                  </Grid>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </Box>
      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'partyidn', direction: 'ASC' }]}
        fetch={{ url: PartyMasterApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partytype', operator: IN, value: ['CN', 'NP', 'SI'] },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'partyidn', operator: LIKE }}
        sortOptions={sortOptions}
        title='Party template'
      />
    </>
  )
}

const PartiesFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default PartiesFormProperties
