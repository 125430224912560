import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )

  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.NUMERIC
  )

  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9 ]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"

  return Yup.object({
    partyType: Yup.string().required(requiredMessage).nullable(),
    partyId: 
    Yup.string().when('saveFlag', {
      is: true,
      then: Yup.string().trim().required(requiredMessage).matches(regexAlphaNumeric, alphaNumericErrorMessage),
    }).nullable(),
    name: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35").nullable(),
    address1: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35").nullable(),
    address2: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35").nullable(),
    address3: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35").nullable(),
    telNo: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(35,maxLengthErrorMessage + " 35").nullable(),
    faxNo: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
  })
}

export default makeValidationSchema
