import makeValidationSchema from './BillOfLadingMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from "react-hook-form";
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import { Grid, Divider, Typography, useTheme, IconButton, Box, InputAdornment, Tooltip } from '@material-ui/core'
import { NaAceStateCodeAutoCompleteField } from 'src/components/na'
import ToggleButtonGroup from '../../../components/aciacehighway/ToggleButtonGroup';
import CngSection from '../../../components/cngcomponents/CngSection';
import IconDescButton from '../../../components/button/IconDescButton';
import CheckboxButton from '../../../components/button/CheckboxButton';
import CollapsibleSection from '../../../components/ui/CollapsibleSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NaHwyAcePOLPortAutocompleteField from 'src/components/na/autocomplete/codemaintenance/NaAceHwyPOLPortAutocompleteField'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import Switch from 'src/components/Switch'
import PartiesEntry from './PartiesEntry'
import PartiesFormProperties from './PartiesFormProperties'
import BolContainerTable from './BolContainerTable'
import BolContainerDialog from './BolContainerDialog'
import { useLocation } from 'react-router-dom'

const {
  button: { CngButton, CngIconButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  actionCode: "",
  masterBolIssuerCode: "",
  masterBillNumber: "",
  houseBillNumber: "",
  previousInbondNumberEntryType: "",
  previousInbondNumberEntryTypeCode: "",
  previousInbondNumber: "",
  inbondQuantity: "",
  secondaryNotifyPartyCode1: "",
  secondaryNotifyPartyCode2: "",
  secondaryNotifyPartyCode3: "",
  secondaryNotifyPartyCode4: "",
  referenceInformation: [],
  additionalBOLIndicator: false,
  foreignPortOfLading: "",
  manifestQuantity: "",
  manifestUnit: "",
  weight: "",
  weightUnit: "",
  volume: "",
  volumeUnit: "",
  placeOfPrereceipt: "",
  bolContainer: [],
  parties: [],
  containerizedIndicator: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

const referenceInformationInitialValues = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  submittedBy: "",
  submittedDate: "",
  version: "",
  qualifier: "",
  referenceIdentifier: ""
})

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  ftz,
  mot
}) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue, getValues, watch, trigger } = useFormContext();
  const location = useLocation();
  const editPage = location.pathname.toString().indexOf("edit") != -1 ? true : false

  const foreignPortOfLading = watch('foreignPortOfLading')
  const referenceInformation = watch('referenceInformation')
  const additionalBOLIndicator = watch('additionalBOLIndicator')
  const containerizedIndicator = watch('containerizedIndicator')
  const previousInbondNumberEntryType = watch('previousInbondNumberEntryType')
  const parties = watch('parties')
  const notifyPartyCodes = watch([
    'secondaryNotifyPartyCode1',
    'secondaryNotifyPartyCode2',
    'secondaryNotifyPartyCode3',
    'secondaryNotifyPartyCode4'
  ])
  const bolHasValue = watch([
    'manifestQuantity',
    'manifestUnit',
    'weight',
    'weightUnit',
    'volume',
    'volumeUnit',
    'placeOfPrereceipt'
  ])
  const bolContainer = watch('bolContainer')
  const [bolContainerDialog, setBolContainerDialog] = useState({
    open: false,
    bolContainer: null,
    isView: false
  })

  useEffect(() => {
    if(additionalBOLIndicator){
      const { initialValues } = PartiesFormProperties.formikProps

      if (parties.length === 0) {
        setValue('parties', [
          { ...initialValues, partyType: 'SI', _id: 1 },
          { ...initialValues, partyType: 'CN', _id: 2 }
        ])
      } else {
        const hasShipper = parties.findIndex(party => party.partyType === 'SI') !== -1
        const hasConsignee = parties.findIndex(party => party.partyType === 'CN') !== -1

        if(!hasShipper || !hasConsignee) {
          const result = [...getValues('parties')]

          if(!hasShipper) {
            result.push({...initialValues, partyType: 'SI' })
          } else if (!hasConsignee) {
            result.push({...initialValues, partyType: 'CN' })
          }

          setValue('parties', result.map((party, index) => ({ ...party, _id: index + 1 })))
        }
      }
    }
  
  }, [additionalBOLIndicator, parties])

  useEffect(() => {
    if(bolHasValue.filter((bolFields) => bolFields).length > 0 || bolContainer.length > 0){
        setValue('additionalBOLIndicator', true)
      }
  }, [foreignPortOfLading,parties,bolContainer])

  useEffect(() => {
    if(previousInbondNumberEntryType === "61"){
      setValue('previousInbondNumberEntryTypeCode', "IT")
    }
    if(previousInbondNumberEntryType === "62"){
      setValue('previousInbondNumberEntryTypeCode', "T&E")
    }
    if(previousInbondNumberEntryType === "63"){
      setValue('previousInbondNumberEntryTypeCode', "IE")
    }
  }, [previousInbondNumberEntryType])

  useEffect(() => {
    if("30" === mot || "70" === mot){
      setValue('houseBillNumber', "")
    }

    if("40" === mot){
      setValue('inbondQuantity', "")
    }
  }, [mot]);

  // useEffect(() => {
  //   if(notifyPartyCodes.filter((value) => value !== '').length > 0 && referenceInformation.length > 0){
  //     setValue("secondaryNotifyPartyCode1", "")
  //     setValue("secondaryNotifyPartyCode2", "")
  //     setValue("secondaryNotifyPartyCode3", "")
  //     setValue("secondaryNotifyPartyCode4", "")
  //   }
  // }, [referenceInformation])

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    if (editPage && additionalBOLIndicator && bolContainer.length > 0) {
      setValue('containerizedIndicator', true, { shouldValidate: true })
    }else if (!editPage && additionalBOLIndicator) {
      setValue('containerizedIndicator', true, { shouldValidate: true })
    }else if(!additionalBOLIndicator){
      setValue('containerizedIndicator', false, { shouldValidate: true })
    }
  }, [additionalBOLIndicator])

  function makeTranslatedTextsObject() {
    let billOfLadingTitle = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.TITLE
    )
    let actionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ACTION_CODE
    )
    let masterBolIssuerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.MASTER_BOL_ISSUER_CODE
    )
    let masterBillNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.MASTER_BILL_NUMBER
    )
    let houseBillNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.HOUSE_BILL_NUMBER
    )
    let previousEntryType = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.PREVIOUS_ENTRY_TYPE
    )
    let previousInbondNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.PREVIOUS_INBOND_NUMBER
    )
    let inbondQuantity = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.INBOND_QUANTITY
    )
    let secondaryNotifyPartyCode1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.SECONDARY_NOTIFY_PARTY_CODE_1
    )
    let secondaryNotifyPartyCode2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.SECONDARY_NOTIFY_PARTY_CODE_2
    )
    let secondaryNotifyPartyCode3 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.SECONDARY_NOTIFY_PARTY_CODE_3
    )
    let secondaryNotifyPartyCode4 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.SECONDARY_NOTIFY_PARTY_CODE_4
    )
    let referenceInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.REFERENCE_INFORMATION
    )
    let qualifier = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.QUALIFIER
    )
    let referenceIdentifier = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.REFERENCE_IDENTIFIER
    )
    let additionalBOLIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.ADDITIONAL_BOL_INDICATOR
    )
    let containerizedIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.CONTAINERIZED_INDICATOR
    )
    let bolDetails = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.TITLE
    )
    let foreignPortOfLading = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.FOREIGN_PORT_OF_LADING
    )
    let manifestQuantity = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.MANIFEST_QUANTITY
    )
    let manifestUnit = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.MANIFEST_UNIT
    )
    let weight = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.WEIGHT
    )
    let weightUnit = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.WEIGHT_UNIT
    )
    let volume = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.VOLUME
    )
    let volumeUnit = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.VOLUME_UNIT
    )
    let placeOfPrereceipt = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.AdditionalBolDetails.PLACE_OF_PRERECEIPT
    )
    let partiesTitle = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.Parties.TITLE
    )
    let bolContainer = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.TITLE
    )
    let containerNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_NUMBER
    )
    let sealNumber1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER1
    )
    let sealNumber2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER2
    )
    let containerDescriptionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_DESCRIPTION_CODE
    )
    let billCargoDesc = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.TITLE
    )
    let pieceCount = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.PIECE_COUNT
    )
    let description = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.DESCRIPTION
    )
    let manifestUnitCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.MANIFEST_UNIT_CODE
    )
    let htsInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.TITLE
    )
    let harmonizedNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.HARMONIZED_NUMBER
    )
    let htsValue = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.VALUE
    )
    let htsWeight = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.WEIGHT
    )
    let htsWeightUom = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.WEIGHT_UNIT
    )

    return {
      billOfLadingTitle,
      actionCode,
      masterBolIssuerCode,
      masterBillNumber,
      houseBillNumber,
      previousEntryType,
      previousInbondNumber,
      inbondQuantity,
      secondaryNotifyPartyCode1,
      secondaryNotifyPartyCode2,
      secondaryNotifyPartyCode3,
      secondaryNotifyPartyCode4,
      referenceInformation,
      qualifier,
      referenceIdentifier,
      additionalBOLIndicator,
      containerizedIndicator,
      bolDetails,
      foreignPortOfLading,
      manifestQuantity,
      manifestUnit,
      weight,
      weightUnit,
      volume,
      volumeUnit,
      placeOfPrereceipt,
      partiesTitle,
      bolContainer,
      containerNumber,
      sealNumber1,
      sealNumber2,
      containerDescriptionCode,
      billCargoDesc,
      pieceCount,
      description,
      manifestUnitCode,
      htsInformation,
      harmonizedNumber,
      htsValue,
      htsWeight,
      htsWeightUom
    }
  }

  function handleAddReferenceInformation(data) {
    const referenceInformation = [...getValues('referenceInformation'), data].map(
      (reference, index) => ({ ...reference, _id: index + 1 })
    )

    setValue('referenceInformation', referenceInformation)
  }

  function handleRemoveReferenceInformation(data) {
    const clonedReferenceInformation = [...getValues('referenceInformation')].filter(
      (reference) => reference.id ? reference.id !== data.id : reference._id !== data._id
    )
    const referenceInformation = clonedReferenceInformation.map(
      (reference, index) => ({ ...reference, _id: index + 1 })
    )

    setValue('referenceInformation', referenceInformation)
  }

  function handleAddParties(data) {
    const parties = [...getValues('parties'), data].map(
      (party, index) => ({ ...party, _id: index + 1 })
    )

    setValue('parties', parties, { shouldValidate: true })
  }

  function handleEditParties(data) {
    const parties = [...getValues('parties')]
    const index = parties.findIndex((party) =>
      party.id ? party.id === data.id : party._id === data._id
    )
    
    parties[index] = data
    setValue('parties', parties, { shouldValidate: true })
  }

  function handleDeleteParties(data) {
    const clonedParties = [...getValues('parties')].filter(
      (party) => party.id ? party.id !== data.id : party._id !== data._id
    )
    const parties = clonedParties.map(
      (party, index) => ({ ...party, _id: index + 1 })
    )

    setValue('parties', parties, { shouldValidate: true })
  }

  function handleDeleteBolContainer(data) {
    const bolContainer = [...getValues('bolContainer')].filter((container) =>
    container._id ? container._id !== data._id : container.id !== data.id
    )

    setValue('bolContainer', bolContainer, { shouldValidate: true })
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.actionCode}>
              <CngSelectField
                required
                size='small'
                name="actionCode"
                label={translatedTextsObject.actionCode}
                disabled={disabled}
                items={[{ text: "Add", value: "A" },{ text: "Delete", value: "D" }]}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.masterBolIssuerCode}>
              <CngTextField
                required
                size='small'
                name="masterBolIssuerCode"
                inputProps={{ maxLength: 4 }}
                label={translatedTextsObject.masterBolIssuerCode}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.masterBillNumber}>
              <CngTextField
                required
                size='small'
                name="masterBillNumber"
                inputProps={{ maxLength: 12 }}
                label={translatedTextsObject.masterBillNumber}
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.houseBillNumber}>
              <CngTextField
                size='small'
                name="houseBillNumber"
                inputProps={{ maxLength: 12 }}
                label={translatedTextsObject.houseBillNumber}
                disabled={disabled || mot !== "40"}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={2} md={2} shouldHide={shouldHideMap.previousEntryType}>
              <CngCodeMasterAutocompleteField
                name='previousInbondNumberEntryType'
                label={translatedTextsObject.previousEntryType}
                disabled={disabled || ftz}
                codeType='ACE_HW_ABI_ENTRY_TYPE'
                size='small'
                onChange={(e) =>
                  setValue('previousInbondNumber', "")
                }
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} md={2} shouldHide={shouldHideMap.previousInbondNumber}>
              <CngTextField
                size='small'
                name="previousInbondNumber"
                inputProps={{ maxLength: 12 }}
                label={translatedTextsObject.previousInbondNumber}
                disabled={!getValues("previousInbondNumberEntryType") || disabled || ftz}
              />
            </CngGridItem>
            
            <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.inbondQuantity}>
              <CngTextField
                size='small'
                name="inbondQuantity"
                inputProps={{ maxLength: 10 }}
                label={translatedTextsObject.inbondQuantity}
                disabled={disabled || mot == "40"}
              />
            </CngGridItem>
          </Grid>
        </Grid>
      
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={notifyPartyCodes.filter((notifyPartyCode) => notifyPartyCode).length > 0}
            title='Secondary Notify Party Code'
            footerText={translatedTextsObject.additionalSealNoFooterText}
            hideText={translatedTextsObject.additionalSealNoHideText}
            showText={translatedTextsObject.additionalSealNoShowText}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondaryNotifyPartyCode}>
                <CngTextField
                  required={additionalBOLIndicator}
                  size='small'
                  name='secondaryNotifyPartyCode1'
                  inputProps={{ maxLength: 9 }}
                  label={translatedTextsObject.secondaryNotifyPartyCode1}
                  disabled={disabled}
                  // onChange={(e) =>
                  //   setValue('referenceInformation', [])
                  // }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondaryNotifyPartyCode2}>
                <CngTextField
                  size='small'
                  name='secondaryNotifyPartyCode2'
                  inputProps={{ maxLength: 9 }}
                  label={translatedTextsObject.secondaryNotifyPartyCode2}
                  disabled={disabled}
                  // onChange={(e) =>
                  //   setValue('referenceInformation', [])
                  // }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondaryNotifyPartyCode3}>
                <CngTextField
                  size='small'
                  name='secondaryNotifyPartyCode3'
                  inputProps={{ maxLength: 9 }}
                  label={translatedTextsObject.secondaryNotifyPartyCode3}
                  disabled={disabled}
                  // onChange={(e) =>
                  //   setValue('referenceInformation', [])
                  // }
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.secondaryNotifyPartyCode4}>
                <CngTextField
                  size='small'
                  name='secondaryNotifyPartyCode4'
                  inputProps={{ maxLength: 9 }}
                  label={translatedTextsObject.secondaryNotifyPartyCode4}
                  disabled={disabled}
                  // onChange={(e) =>
                  //   setValue('referenceInformation', [])
                  // }
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </Grid>

        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.referenceInformation}>
            <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
              {referenceInformation.map((referenceInfo, index) => (
                <React.Fragment key={referenceInfo._id || referenceInfo.id || index}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} md>
                      <Grid container spacing={1}>
                        <CngGridItem xs={12} md={5}>
                          <CngCodeMasterAutocompleteField
                            required={additionalBOLIndicator || getValues(`referenceInformation.${index}.referenceIdentifier`)}
                            name={`referenceInformation.${index}.qualifier`}
                            label={translatedTextsObject.qualifier}
                            disabled={disabled}
                            codeType='ACE_HW_ABI_REF_QUAL'
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs={12} md={7}>
                          <CngTextField
                            required={getValues(`referenceInformation.${index}.qualifier`)}
                            size='small'
                            name={`referenceInformation.${index}.referenceIdentifier`}
                            inputProps={{ maxLength: 30 }}
                            label={translatedTextsObject.referenceIdentifier}
                            disabled={disabled}
                          />
                        </CngGridItem>
                      </Grid>
                    </CngGridItem>
                    <CngGridItem xs={12} md='auto'>
                      <Grid container spacing={1} alignItems='center'>
                        <CngGridItem xs='auto'>
                          <CngIconButton
                            type='outlined'
                            icon={['fal', 'plus']}
                            onClick={() => handleAddReferenceInformation(referenceInformationInitialValues)}
                            size='small'
                          />
                        </CngGridItem>
                        <CngGridItem xs='auto'>
                          <CngIconButton
                            type='outlined'
                            icon={['fal', 'copy']}
                            onClick={() => handleAddReferenceInformation({ ...referenceInfo, id: undefined })}
                            size='small'
                          />
                        </CngGridItem>
                        {referenceInformation.length > 0 && (
                          <CngGridItem xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={() => handleRemoveReferenceInformation(referenceInfo)}
                              size='small'
                            />
                          </CngGridItem>
                        )}
                      </Grid>
                    </CngGridItem>
                  </Grid>
                  {referenceInformation.length !== index + 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
            {referenceInformation.length === 0 && (
              <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add new'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => handleAddReferenceInformation(referenceInformationInitialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            )}
          </CngSection>
        </Grid>
        
        <Grid item xs={6}>
          <Switch
            disabled={disabled}
            fullWidth
            label={translatedTextsObject.additionalBOLIndicator}
            name='additionalBOLIndicator'
          />
        </Grid>

        {additionalBOLIndicator && (
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.bolDetails}>
            <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.foreignPortOfLading}>
                  <NaHwyAcePOLPortAutocompleteField
                    required
                    size='small'
                    name='foreignPortOfLading'
                    label={translatedTextsObject.foreignPortOfLading}
                    disabled={disabled}
                    key={getValues("foreignPortOfLading")}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.manifestQuantity}>
                  <CngTextField
                    required
                    size='small'
                    name='manifestQuantity'
                    inputProps={{ maxLength: 10 }}
                    label={translatedTextsObject.manifestQuantity}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={2} shouldHide={shouldHideMap.manifestUnit}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='manifestUnit'
                    label={translatedTextsObject.manifestUnit}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_MANIFEST_UOM'
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.weight}>
                  <CngTextField
                    required
                    size='small'
                    name='weight'
                    inputProps={{ maxLength: 10 }}
                    label={translatedTextsObject.weight}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={2} shouldHide={shouldHideMap.weightUnit}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='weightUnit'
                    label={translatedTextsObject.weightUnit}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_WEIGHT_UOM'
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.volume}>
                  <CngTextField
                    size='small'
                    name='volume'
                    inputProps={{ maxLength: 10 }}
                    label={translatedTextsObject.volume}
                    disabled={disabled}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={2} shouldHide={shouldHideMap.volumeUnit}>
                  <CngCodeMasterAutocompleteField
                    required={getValues("volume")}
                    name='volumeUnit'
                    label={translatedTextsObject.volumeUnit}
                    disabled={disabled}
                    codeType='ACE_HW_ABI_VOLUME_UOM'
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfPrereceipt}>
                  <CngTextField
                    size='small'
                    name='placeOfPrereceipt'
                    inputProps={{ maxLength: 17 }}
                    label={translatedTextsObject.placeOfPrereceipt}
                    disabled={disabled}
                  />
                </CngGridItem>
              </Grid>
            </Box>
          </CngSection>

          <CngSection title={translatedTextsObject.partiesTitle}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>
                  Note: 2 parties [Consignee and Shipper] are required.
                </Typography>
              </Typography>
            }
          >
          <Grid container spacing={2}>
              {parties.map((party) => (
                <Grid key={Math.random()} item xs={12}>
                  <PartiesEntry
                    isLast={parties.length === 1}
                    onAddParties={handleAddParties}
                    onDeleteParties={handleDeleteParties}
                    onEditParties={handleEditParties}
                    parties={party}
                    showNotification={showNotification}
                  />
                </Grid>
              ))}
              {parties.length === 0 && (
                <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs='auto'>
                    <Tooltip placement='bottom' title='Add new'>
                      <span>
                        <CngIconButton
                          icon={['fal', 'plus']}
                          onClick={() => handleAddParties(PartiesFormProperties.formikProps.initialValues)}
                          size='small'
                          type='outlined'
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              )}
          </Grid>
          </CngSection>
        </Grid>
        )}
      
      <Grid item xs={6}>
        <Switch
          disabled={!additionalBOLIndicator}
          fullWidth
          label={translatedTextsObject.containerizedIndicator}
          name='containerizedIndicator'
        />
      </Grid>

      {containerizedIndicator && (
        <>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.bolContainer}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>
                  Note: Please add at least one Bill of Lading Container.
                </Typography>
              </Typography>
            }
          >
            <BolContainerTable
              bolContainer={bolContainer}
              onAddBolContainer={() =>
                setBolContainerDialog({
                  open: true,
                  bolContainer: null,
                  isView: false
                })
              }
              onDeleteBolContainer={handleDeleteBolContainer}
              onEditBolContainer={(bolContainer) =>
                setBolContainerDialog({
                  open: true,
                  bolContainer,
                  isView: false
                })
              }
              onViewBolContainer={(bolContainer) =>
                setBolContainerDialog({
                  open: true,
                  bolContainer,
                  isView: true
                })
              }
            />
            <BolContainerDialog
              bolContainer={bolContainerDialog.bolContainer}
              isView={bolContainerDialog.isView}
              open={bolContainerDialog.open}
              onClose={() =>
                setBolContainerDialog({
                  open: false,
                  bolContainer: null,
                  isView: false
                })
              }
              showNotification={showNotification}
            />
          </CngSection>
        </Grid>
        </>
      )}
      </Grid>
    </>
	)
}

const BillOfLadingFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default BillOfLadingFormProperties
