import React from 'react'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ResponseLog from '../manifest/view/ResponseLog'
import History from '../manifest/view/History'
import moment from 'moment-timezone'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

function Inbond7512ViewContent(props) {
  const {
    data,
    getCountryStateLabel,
    getLookupValue,
    id,
    translatedTextsObject
  } = props

  const theme = useTheme()

  // const isFtz =
  //   data.ftzIndicator === 'Y' ? true : data.ftzIndicator === 'N' && false
  const isFtz = data.ftzIndicator
  const isBtaFda = data.btaFdaIndicator

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.status} />
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.abiInbond7512}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.filerCode}>
                {data.filerCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.portCode}>
                {data.portCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.actionCode}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('actionCode', data.actionCode)}
                  <TinyChip label={data.actionCode} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.bondNumber}>
                {data.bondNumber}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <CngField label={translatedTextsObject.entryType}>
                {data.entryType}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.inbondCarrierCode}>
                {data.inbondCarrierCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.destinationPort}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('portOfArrival', data.destinationPort)}
                  <TinyChip label={data.destinationPort} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.foreignPort}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('portOfArrival', data.foreignPort)}
                  <TinyChip label={data.foreignPort} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.inbondValue}>
                {data.inbondValue}
              </CngField>
            </Grid>
            
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.inbondCarrierId}>
                {data.inbondCarrierId}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField>
                <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                  <FontAwesomeIcon
                    color={
                      isFtz
                        ? theme.palette.primary.main
                        : theme.palette.error.main
                    }
                    icon={isFtz ? faCheckCircle : faBan}
                  />
                  <Typography variant='body2'>
                    {isFtz ? 'FTZ regulated' : 'Not FTZ regulated'}
                  </Typography>
                </Box>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <CngField>
                  <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                    <FontAwesomeIcon
                      color={
                        isFtz
                          ? theme.palette.primary.main
                          : theme.palette.error.main
                      }
                      icon={isBtaFda ? faCheckCircle : faBan}
                    />
                    <Typography variant='body2'>
                      {isBtaFda ? 'BTA/FDA regulated' : 'Not BTA/FDA regulated'}
                    </Typography>
                  </Box>
                </CngField>
              </Grid>
          </Grid>
        </CngSection>
      </Grid>
      
      {/* <Grid item xs={12}>
        <CngSection title={translatedTextsObject.statusNotificationTitle}>
          {data.statusNotificationInfo.length > 0 ? (
            <Grid container spacing={3}>
              {data.statusNotificationInfo.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationName}>
                          {notification.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationEmail}>
                          {notification.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationSnType}>
                          {notification.snType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationShipmentControlNum}>
                          {notification.shipmentControlNum}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationMobileNum}>
                          {notification.mobileNum}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.statusNotificationInfo.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='inherit' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid> */}
      {/* {!isReviewPage && (
        <>
          <Grid item xs={12}>
            <ResponseLog manifestId={id} />
          </Grid>
          <Grid item xs={12}>
            <History manifestId={id} />
          </Grid>
        </>
      )} */}
    </Grid>
  )
}

export default Inbond7512ViewContent
