import makeValidationSchema from './BolContainerMakeValidationSchema'
import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import { components, useTranslation } from 'cng-web-lib'
import { Grid, Divider, Typography, Tooltip, Box } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import CngSection from 'src/components/cngcomponents/CngSection'
import CollapsibleSection from 'src/components/ui/CollapsibleSection'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import  HazmatInfoEntry from './HazmatInfoEntry'
import  HazmatInfoFormProperties from './HazmatInfoFormProperties'

const {
  button: { CngIconButton },
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNumber: "",
  sealNumber1: "",
  sealNumber2: "",
  containerDescriptionCode: "",
  billCargoDesc: [],
  htsInformation: [],
  marksAndNumbers: [],
  hazmatInformation: []
})

const billCargoDescInitialValues = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  version: "",
  pieceCount: "",
  description: "",
  manifestUnitCode: ""
})

const htsInformationInitialValues = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  version: "",
  harmonizedNumber: "",
  value: "",
  weight: "",
  weightUnit: ""
})

const marksAndNumbersInitialValues = Object.freeze({
  id: "",
  headerId: "",
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  version: "",
  marksAndNumbers: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { setValue, getValues, watch } = useFormContext()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()
  const billCargoDesc = watch('billCargoDesc')
  const htsInformation = watch('htsInformation')
  const marksAndNumbers = watch('marksAndNumbers')
  const hazmatInformation = watch('hazmatInformation')
 
  useEffect(() => {
    if(billCargoDesc.length === 0){
      handleAddBillCargoDesc(billCargoDescInitialValues)
    }
  }, [billCargoDesc]);

  useEffect(() => {
    if(marksAndNumbers.length === 0){
      handleAddMarksAndNumbers(marksAndNumbersInitialValues)
    }
  }, [marksAndNumbers]);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);


  function makeTranslatedTextsObject() {
    let bolContainer = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.TITLE
    )
    let containerNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_NUMBER
    )
    let sealNumber1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER1
    )
    let sealNumber2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER2
    )
    let containerDescriptionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_DESCRIPTION_CODE
    )
    let billCargoDesc = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.TITLE
    )
    let pieceCount = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.PIECE_COUNT
    )
    let description = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.DESCRIPTION
    )
    let manifestUnitCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.BillCargoDesc.MANIFEST_UNIT_CODE
    )
    let htsInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.TITLE
    )
    let harmonizedNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.HARMONIZED_NUMBER
    )
    let htsValue = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.VALUE
    )
    let htsWeight = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.WEIGHT
    )
    let htsWeightUom = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HtsInformation.WEIGHT_UNIT
    )
    let marksAndNumbersTitle = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.MarksAndNumbers.TITLE
    )
    let marksAndNumbers = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.MarksAndNumbers.MARKS_AND_NUMBERS
    )
    let hazmatInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.TITLE
    )

    return {
      bolContainer,
      containerNumber,
      sealNumber1,
      sealNumber2,
      containerDescriptionCode,
      billCargoDesc,
      pieceCount,
      description,
      manifestUnitCode,
      htsInformation,
      harmonizedNumber,
      htsValue,
      htsWeight,
      htsWeightUom,
      marksAndNumbersTitle,
      marksAndNumbers,
      hazmatInformation
    }
  }

  function handleAddBillCargoDesc(data) {
    const billCargoDesc = [...getValues('billCargoDesc'), data].map(
      (cargoDesc, index) => ({ ...cargoDesc, _id: index + 1 })
    )

    setValue('billCargoDesc', billCargoDesc)
  }

  function handleRemoveBillCargoDesc(data) {
    const clonedBillCargoDesc = [...getValues('billCargoDesc')].filter(
      (cargoDesc) => cargoDesc.id ? cargoDesc.id !== data.id : cargoDesc._id !== data._id
    )
    const billCargoDesc = clonedBillCargoDesc.map(
      (cargoDesc, index) => ({ ...cargoDesc, _id: index + 1 })
    )

    setValue('billCargoDesc', billCargoDesc)
  }

  function handleAddHtsInformation(data) {
    const htsInformation = [...getValues('htsInformation'), data].map(
      (hts, index) => ({ ...hts, _id: index + 1 })
    )

    setValue('htsInformation', htsInformation)
  }

  function handleRemoveHtsInformation(data) {
    const clonedHtsInformation = [...getValues('htsInformation')].filter(
      (hts) => hts.id ? hts.id !== data.id : hts._id !== data._id
    )
    const htsInformation = clonedHtsInformation.map(
      (hts, index) => ({ ...hts, _id: index + 1 })
    )

    setValue('htsInformation', htsInformation)
  }

  function handleAddMarksAndNumbers(data) {
    const marksAndNumbers = [...getValues('marksAndNumbers'), data].map(
      (mn, index) => ({ ...mn, _id: index + 1 })
    )

    setValue('marksAndNumbers', marksAndNumbers)
  }

  function handleRemoveMarksAndNumbers(data) {
    const clonedMarksAndNumbers = [...getValues('marksAndNumbers')].filter(
      (mn) => mn.id ? mn.id !== data.id : mn._id !== data._id
    )
    const marksAndNumbers = clonedMarksAndNumbers.map(
      (mn, index) => ({ ...mn, _id: index + 1 })
    )

    setValue('marksAndNumbers', marksAndNumbers)
  }

  function handleAddHazmatInfo(data) {
    const hazmatInformation = [...getValues('hazmatInformation'), data].map(
      (hazmat, index) => ({ ...hazmat, _id: index + 1 })
    )

    setValue('hazmatInformation', hazmatInformation)
  }

  function handleDeleteHazmatInfo(data) {
    const clonedHazmatInformation = [...getValues('hazmatInformation')].filter(
      (hazmat) => hazmat.id ? hazmat.id !== data.id : hazmat._id !== data._id
    )
    const hazmatInformation = clonedHazmatInformation.map(
      (hazmat, index) => ({ ...hazmat, _id: index + 1 })
    )

    setValue('hazmatInformation', hazmatInformation)
  }

  function handleEditHazmatInfo(data) {
    const hazmatInformation = [...getValues('hazmatInformation')]
    const index = hazmatInformation.findIndex((hazmat) =>
      hazmat.id ? hazmat.id === data.id : hazmat._id === data._id
    )
    
    hazmatInformation[index] = data
    setValue('hazmatInformation', hazmatInformation, { shouldValidate: true })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.bolContainer}
            subheader={
              <Typography color="textSecondary" component="span" variant="caption">
                <Typography color="error" component="span" variant="inherit">*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
                  <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerNumber}>
                      <CngTextField
                        required
                        size='small'
                        name='containerNumber'
                        label={translatedTextsObject.containerNumber}
                        disabled={disabled}
                        onChange={(e) => {
                          setValue('containerNumber', e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== undefined) {
                            setValue('containerNumber', e.target.value.trim(), { shouldValidate: true })
                          }
                        }}
                        inputProps={{ maxLength: 14 }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNumber1}>
                      <CngTextField
                        size='small'
                        name='sealNumber1'
                        label={translatedTextsObject.sealNumber1}
                        disabled={disabled}
                        onChange={(e) => {
                          setValue('sealNumber1', e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== undefined) {
                            setValue('sealNumber1', e.target.value.trim(), { shouldValidate: true })
                          }
                        }}
                        inputProps={{ maxLength: 15 }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sealNumber2}>
                      <CngTextField
                        size='small'
                        name='sealNumber2'
                        label={translatedTextsObject.sealNumber2}
                        disabled={disabled}
                        onChange={(e) => {
                          setValue('sealNumber2', e.target.value.toUpperCase(), { shouldValidate: true })
                        }}
                        onBlur={(e) => {
                          if (e.target.value !== undefined) {
                            setValue('sealNumber2', e.target.value.trim(), { shouldValidate: true })
                          }
                        }}
                        inputProps={{ maxLength: 15 }}
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerDescriptionCode}>
                      <CngCodeMasterAutocompleteField
                        name='containerDescriptionCode'
                        label={translatedTextsObject.containerDescriptionCode}
                        disabled={disabled}
                        codeType='ACE_HW_ABI_CONTAINER_DESC'
                        size='small'
                      />
                    </CngGridItem>
                  </Grid>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.billCargoDesc}>
                  <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
                    {billCargoDesc.map((cargoDesc, index) => (
                      <React.Fragment key={cargoDesc._id || cargoDesc.id || index}>
                        <Grid container spacing={1}>
                          <CngGridItem xs={12} md>
                            <Grid container spacing={1}>
                              <CngGridItem xs={12} md={4}>
                                <CngTextField
                                  size='small'
                                  required
                                  name={`billCargoDesc.${index}.pieceCount`}
                                  label={translatedTextsObject.pieceCount}
                                  disabled={disabled}
                                  inputProps={{ maxLength: 10 }}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4}>
                                <CngTextField
                                  size='small'
                                  required
                                  name={`billCargoDesc.${index}.description`}
                                  label={translatedTextsObject.description}
                                  disabled={disabled}
                                  inputProps={{ maxLength: 45 }}
                                  onChange={(e) => {
                                    setValue(`billCargoDesc.${index}.description`, e.target.value.toUpperCase(), { shouldValidate: true })
                                  }}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={4}>
                                <CngCodeMasterAutocompleteField
                                  required
                                  name={`billCargoDesc.${index}.manifestUnitCode`}
                                  label={translatedTextsObject.manifestUnitCode}
                                  disabled={disabled}
                                  codeType='ACE_HW_ABI_MANIFEST_UOM'
                                  size='small'
                                />
                              </CngGridItem>
                            </Grid>
                          </CngGridItem>
                          <CngGridItem xs={12} md='auto'>
                            <Grid container spacing={1} alignItems='center'>
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'plus']}
                                  onClick={() => handleAddBillCargoDesc(billCargoDescInitialValues)}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'copy']}
                                  onClick={() => handleAddBillCargoDesc({ ...cargoDesc, id: undefined })}
                                  size='small'
                                />
                              </CngGridItem>
                              {billCargoDesc.length > 1 && (
                                <CngGridItem xs='auto'>
                                  <CngIconButton
                                    type='outlined'
                                    icon={['fal', 'trash']}
                                    onClick={() => handleRemoveBillCargoDesc(cargoDesc)}
                                    size='small'
                                  />
                                </CngGridItem>
                              )}
                            </Grid>
                          </CngGridItem>
                        </Grid>
                        {billCargoDesc.length !== index + 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </Box>
                  {billCargoDesc.length === 0 && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Add new'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'plus']}
                                onClick={() => handleAddBillCargoDesc(billCargoDescInitialValues)}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </CngSection>
              </Grid>

              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.htsInformation}>
                  <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
                    {htsInformation.map((hts, index) => (
                      <React.Fragment key={hts._id || hts.id || index}>
                        <Grid container spacing={1}>
                          <CngGridItem xs={12} md>
                            <Grid container spacing={1}>
                              <CngGridItem xs={12} md={3}>
                                <CngTextField
                                  size='small'
                                  required
                                  name={`htsInformation.${index}.harmonizedNumber`}
                                  label={translatedTextsObject.harmonizedNumber}
                                  disabled={disabled}
                                  inputProps={{ maxLength: 10 }}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={3}>
                                <CngTextField
                                  size='small'
                                  required
                                  name={`htsInformation.${index}.value`}
                                  label={translatedTextsObject.htsValue}
                                  disabled={disabled}
                                  inputProps={{ maxLength: 8 }}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={3}>
                                <CngTextField
                                  size='small'
                                  required
                                  name={`htsInformation.${index}.weight`}
                                  label={translatedTextsObject.htsWeight}
                                  disabled={disabled}
                                  inputProps={{ maxLength: 10 }}
                                />
                              </CngGridItem>
                              <CngGridItem xs={12} md={3}>
                                <CngCodeMasterAutocompleteField
                                  required
                                  name={`htsInformation.${index}.weightUnit`}
                                  label={translatedTextsObject.htsWeightUom}
                                  disabled={disabled}
                                  codeType='ACE_HW_ABI_WEIGHT_UOM'
                                  size='small'
                                />
                              </CngGridItem>
                            </Grid>
                          </CngGridItem>
                          <CngGridItem xs={12} md='auto'>
                            <Grid container spacing={1} alignItems='center'>
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'plus']}
                                  onClick={() => handleAddHtsInformation(htsInformationInitialValues)}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'copy']}
                                  onClick={() => handleAddHtsInformation({ ...hts, id: undefined })}
                                  size='small'
                                />
                              </CngGridItem>
                              {htsInformation.length > 0 && (
                                <CngGridItem xs='auto'>
                                  <CngIconButton
                                    type='outlined'
                                    icon={['fal', 'trash']}
                                    onClick={() => handleRemoveHtsInformation(hts)}
                                    size='small'
                                  />
                                </CngGridItem>
                              )}
                            </Grid>
                          </CngGridItem>
                        </Grid>
                        {htsInformation.length !== index + 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </Box>
                  {htsInformation.length === 0 && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Add new'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'plus']}
                                onClick={() => handleAddHtsInformation(htsInformationInitialValues)}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </CngSection>
              </Grid>

              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.marksAndNumbersTitle}>
                  <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
                    {marksAndNumbers.map((marks, index) => (
                      <React.Fragment key={marks._id || marks.id || index}>
                        <Grid container spacing={1}>
                          <CngGridItem xs={12} md>
                            <Grid container spacing={1}>
                              <CngGridItem xs={12} md={6}>
                                <CngTextField
                                  size='small'
                                  required
                                  name={`marksAndNumbers.${index}.marksAndNumbers`}
                                  label={translatedTextsObject.marksAndNumbers}
                                  disabled={disabled}
                                  inputProps={{ maxLength: 45 }}
                                />
                              </CngGridItem>
                            </Grid>
                          </CngGridItem>
                          <CngGridItem xs={12} md='auto'>
                            <Grid container spacing={1} alignItems='center'>
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'plus']}
                                  onClick={() => handleAddMarksAndNumbers(marksAndNumbersInitialValues)}
                                  size='small'
                                />
                              </CngGridItem>
                              <CngGridItem xs='auto'>
                                <CngIconButton
                                  type='outlined'
                                  icon={['fal', 'copy']}
                                  onClick={() => handleAddMarksAndNumbers({ ...marks, id: undefined })}
                                  size='small'
                                />
                              </CngGridItem>
                              {marksAndNumbers.length > 1 && (
                                <CngGridItem xs='auto'>
                                  <CngIconButton
                                    type='outlined'
                                    icon={['fal', 'trash']}
                                    onClick={() => handleRemoveMarksAndNumbers(marks)}
                                    size='small'
                                  />
                                </CngGridItem>
                              )}
                            </Grid>
                          </CngGridItem>
                        </Grid>
                        {marksAndNumbers.length !== index + 1 && <Divider />}
                      </React.Fragment>
                    ))}
                  </Box>
                  {marksAndNumbers.length === 0 && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Add new'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'plus']}
                                onClick={() => handleAddMarksAndNumbers(marksAndNumbersInitialValues)}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </CngSection>
              </Grid>

              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.hazmatInformation}>
                <Grid container spacing={2}>
                    {hazmatInformation.map((hazmat) => (
                      <Grid key={Math.random()} item xs={12}>
                        <HazmatInfoEntry
                          isLast={hazmatInformation.length === 1}
                          onAddHazmatInfo={handleAddHazmatInfo}
                          onDeleteHazmatInfo={handleDeleteHazmatInfo}
                          onEditHazmatInfo={handleEditHazmatInfo}
                          hazmatInformation={hazmat}
                          showNotification={showNotification}
                        />
                      </Grid>
                    ))}
                    {hazmatInformation.length === 0 && (
                      <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs='auto'>
                          <Tooltip placement='bottom' title='Add new'>
                            <span>
                              <CngIconButton
                                icon={['fal', 'plus']}
                                onClick={() => handleAddHazmatInfo(HazmatInfoFormProperties.formikProps.initialValues)}
                                size='small'
                                type='outlined'
                              />
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                    )}
                </Grid>
                </CngSection>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

const GoodsDetailsFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default GoodsDetailsFormProperties
