import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import InbondEventEditPage from './InbondEventEditForm'
import StatusNotificationListPage from '../manifest/StatusNotificationListPage'
import { Box, Card, useTheme } from '@material-ui/core'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import pathMap from '../../../paths/pathMap'
import Namespace from '../../../constants/locale/Namespace'
import AceHighwayAbiInbondEventKeys from '../../../constants/locale/key/AceHighwayAbiInbondEvent'
import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import { getStatusMetadata } from '../../../common/NACommon'
import axios from 'axios'
import ViewLogsHistory from './ViewLogsHistory'
import FormProperties from './FormProperties'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'

const {
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage(props) {
  const { history, loading, location, onSetLoading, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const data = useRef(state?.data ? state.data : null)
  const [step, setStep] = useState((state?.step ? state.step : 1) - 1)
  const [status, setStatus] = useState(null)
  const [stepIconColor, setStepIconColor] = useState({})
  const [completed, setCompleted] = useState({})
  const theme = useTheme()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })

  const [isSuperParty, setSuperParty] = useState(false);

  useEffect(() => {
    if(data!=null && data!=undefined){
      if(data.current!=null && data.current!=undefined){
        setStatus(data.current.status)
      }
    }
  },[]);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }
  useEffect(() => {
    if (status) {
      if (status !== '1005') {
        setCompleted({ 0: true })
      }

      if (status === '1001') {
        const rejectedColor = getStatusMetadata(status)

        setStepIconColor({ 0: rejectedColor })
      }
    }
  }, [status])

  function makeTranslatedTextsObject() {
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.SUBMIT_SUCCESS_MSG
    )

    return { submitSuccessMsg }
  }

  function preSubmit() {
    const values = { ...data.current, id }
    console.log("preSubmit"+JSON.stringify(values))
    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      data.current = values
      console.log("afterPre"+JSON.stringify(data.current))
      handleSubmit()
    }
  }

  function handleSubmit() {
    onSetLoading(true)
    
    const values = { ...data.current }
console.log("submit"+JSON.stringify(values))
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    var serverData = FormProperties.toServerDataFormat(values)
    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHwyInbondArrivalApiUrls.SUBMIT, DataFlattener.unflatten(serverData))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', translatedTextsObject.submitSuccessMsg)
            history.push(pathMap.ABI_INBOND_EVENT_LIST_VIEW)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
      .finally(() => {
        onSetLoading(false)
      })
  }

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <InbondEventEditPage
            data={data}
            onSetLoading={onSetLoading}
            onSubmit={preSubmit}
            onSetStatus={(status) => setStatus(status)}
            showNotification={showNotification}
            status={status}
            id={id}
            history={history}
          />
        )
      case 1:
        return (
          <StatusNotificationListPage
            history={history}
            showNotification={showNotification}
            manifestId={id}
            onSetLoading={onSetLoading}
            onSubmit={handleSubmit}
            onChangeStep={(step) => setStep(step)}
            step={step}
            isABI={true}
          />
        )
      case 2:
        return (
          <ViewLogsHistory
            id={id}
            history={history}
            showNotification={showNotification}
            onChangeStep={(step) => setStep(step)}
            step={step}
            is7512={false}
          />
        )
    }
  }

  return (
    <>
    <Card>
      <Stepper
        // steps={!status || status === '1005' ? ['Create Inbond Event', 'Set status notification'] : ['Create Inbond Event', 'Set status notification', 'View logs & history']}
        steps={['Create Inbond Event', 'Set status notification', 'View logs & history']}
        activeStep={step}
        completed={completed}
        onSetActiveStep={(step) => setStep(step)}
        stepIconColor={stepIconColor}
      />
      <Box padding={2}>
        {renderContent(step)}
      </Box>
    </Card>
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(updatedData) => {
          console.log("last2nd"+JSON.stringify(updatedData))
          data.current = updatedData
          handleSubmit()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </>
  )
}

export default withFormState(EditPage)
