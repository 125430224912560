import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC
  )
  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.NUMERIC
  )
  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MAX_LENGTH
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )

  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"

  return Yup.object({
    actionCode: Yup.string().required(requiredMessage).nullable(),
    masterBolIssuerCode: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(4, maxLengthErrorMessage + " 4").nullable(),
    masterBillNumber: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12").nullable(),
    houseBillNumber: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12").nullable(),
    previousInbondNumber: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12").nullable(),
    inbondQuantity: Yup.number().typeError(numericErrorMessage).min(1, minLengthErrorMessage + " 1").nullable(),
    secondaryNotifyPartyCode1: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(9,maxLengthErrorMessage + " 9").nullable(),
    secondaryNotifyPartyCode2: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(9,maxLengthErrorMessage + " 9").nullable(),
    secondaryNotifyPartyCode3: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(9,maxLengthErrorMessage + " 9").nullable(),
    secondaryNotifyPartyCode4: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(9,maxLengthErrorMessage + " 9").nullable(),
    referenceInformation: Yup.array(Yup.object({
      qualifier: Yup.string().nullable(),
      referenceIdentifier: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
    })).nullable(),
    additionalBOLIndicator: Yup.boolean().nullable(),
    foreignPortOfLading: Yup.string().when('additionalBOLIndicator', {
      is: (value)=>value === true,
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    manifestQuantity: Yup.number().typeError(numericErrorMessage).when('additionalBOLIndicator', {
      is: (value)=>value === true,
      then: Yup.number().typeError(numericErrorMessage).required(requiredMessage).min(1, minLengthErrorMessage + " 1").nullable(),
    }).nullable(),
    manifestUnit: Yup.string().when('additionalBOLIndicator', {
      is: (value)=>value === true,
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    weight: Yup.number().typeError(numericErrorMessage).when('additionalBOLIndicator', {
      is: (value)=>value === true,
      then: Yup.number().typeError(numericErrorMessage).required(requiredMessage).min(1, minLengthErrorMessage + " 1").nullable(),
    }).nullable(),
    weightUnit: Yup.string().when('additionalBOLIndicator', {
      is: (value)=>value === true,
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    volume: Yup.number().typeError(numericErrorMessage).min(1, minLengthErrorMessage + " 1").nullable(),
    volumeUnit: Yup.string().nullable(),
    placeOfPrereceipt: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(17, maxLengthErrorMessage + " 17").nullable(),
    containerizedIndicator: Yup.boolean().nullable(),
  })
}

export default makeValidationSchema
