import React, { useEffect, useState } from 'react'
import { components, constants, useServices } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import AbiInbondResponse from '../response/AbiInbondResponse'
import { Box, Grid } from '@material-ui/core'
import CngSection from '../../../components/cngcomponents/CngSection'

const { button: { CngButton }, CngTabs } = components
const { filter: { EQUAL } } = constants

function ViewLogsHistory(props) {
  const { history, showNotification, id, onChangeStep, step, is7512 } = props
  const { fetchRecords } = useServices()

  return (
    <>
      <CngSection title='Response Log'>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: 'Inbond Event Response',
              tabContent: <AbiInbondResponse id={id} is7512={is7512} showNotification={showNotification}/>
            }
          ]}
        />
      </CngSection>
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton color='secondary' onClick={() => onChangeStep(step - 1)} size='medium'>
                  Back
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewLogsHistory
