import React from 'react'
import StatusBar from '../../../components/aciacehighway/StatusBar'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngSubSection from '../../../components/cngcomponents/CngSubSection'
import CngField from '../../../components/cngcomponents/CngField'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ResponseLog from '../manifest/view/ResponseLog'
import History from '../manifest/view/History'
import moment from 'moment-timezone'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InbondEventViewContent(props) {
  const {
    data,
    getCountryStateLabel,
    getLookupValue,
    id,
    translatedTextsObject
  } = props

  const theme = useTheme()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StatusBar status={data.status} />
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.abiInbondEvent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.filerCode}>
                {data.filerCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.portCode}>
                {data.portCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.actionCode}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('actionCode', data.msgFunction)}
                  <TinyChip label={data.msgFunction} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.inbondNumber}>
                {data.inbond7512No}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <CngField label={translatedTextsObject.masterBolIssuerCode}>
                {data.masterBolIssuerCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.masterBol}>
                {data.masterBol}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.houseBol}>
                {data.houseBol}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.firmLocation}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('portOfArrival', data.firmLocation)}
                  <TinyChip label={data.firmLocation} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.containerNumber}>
                {data.containerNumber}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.arrivalDate}>
                {data.inbondDate &&
                  moment(data.inbondDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.arrivalPort}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('portOfArrival', data.inbondDestination)}
                  <TinyChip label={data.inbondDestination} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.inbondCarrierCode}>
                {data.inbondCarrierCode}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.city}>
                {data.city}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.bondedCarrierId}>
                {data.bondedCarrierId}
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <CngField label={translatedTextsObject.stateCode}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('states', data.stateCode)}
                  <TinyChip label={data.stateCode} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.exportMot}>
                <Typography component='div' variant='inherit'>
                  {getLookupValue('exportMot', data.exportMot)}
                  <TinyChip label={data.exportMot} variant='outlined' />
                </Typography>
              </CngField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CngField label={translatedTextsObject.exportConveyance}>
                {data.exportConveyance}
              </CngField>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      
      {/* <Grid item xs={12}>
        <CngSection title={translatedTextsObject.statusNotificationTitle}>
          {data.statusNotificationInfo.length > 0 ? (
            <Grid container spacing={3}>
              {data.statusNotificationInfo.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationName}>
                          {notification.name}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationEmail}>
                          {notification.email}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationSnType}>
                          {notification.snType}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationShipmentControlNum}>
                          {notification.shipmentControlNum}
                        </CngField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CngField label={translatedTextsObject.statusNotificationMobileNum}>
                          {notification.mobileNum}
                        </CngField>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.statusNotificationInfo.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography variant='inherit' color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid> */}
      {/* {!isReviewPage && (
        <>
          <Grid item xs={12}>
            <ResponseLog manifestId={id} />
          </Grid>
          <Grid item xs={12}>
            <History manifestId={id} />
          </Grid>
        </>
      )} */}
    </Grid>
  )
}

export default InbondEventViewContent
