import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MAX_LENGTH
  )

  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )

  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.NUMERIC
  )

  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MIN_LENGTH
  )
  
  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"

  return Yup.object({
    hazardousMaterialCode: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(10,maxLengthErrorMessage + " 10").nullable(),
    hazardousMaterialClass: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(4,maxLengthErrorMessage + " 4").nullable(),
    hazardousMaterialCodeQual: Yup.string().nullable(),
    hazardousMaterialDescription: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
    hazardousMaterialContact: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(24,maxLengthErrorMessage + " 24").nullable(),
    flashpointTemperature: Yup.number().typeError(numericErrorMessage).nullable(),
    uomCode: Yup.string().nullable(),
    negativeIndicator: Yup.string().nullable(),
    hazardousMaterialDescription1: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(29,maxLengthErrorMessage + " 29").nullable(),
    hazardousMaterialClassification1: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
    hazardousMaterialDescription2: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(29,maxLengthErrorMessage + " 29").nullable(),
    hazardousMaterialClassification2: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(30,maxLengthErrorMessage + " 30").nullable(),
  })
}

export default makeValidationSchema
