import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import AceHighwayAbiInbond7512ApiUrls from 'src/apiUrls/AceHighwayAbiInbond7512ApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  useTranslation
} from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    manifestData,
    manifestStatus,
    onSetLoading,
    onSetManifestStatus,
    onSubmitManifest,
    showNotification  } = props
  const { id } = useParams()
  const [] = useState([])
  const onSuccessCallback = useRef(() => { })

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AceHighwayAbiInbond7512ApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverData.arrivalDateTimeString = ""
              manifestData.current = serverData
              onSetManifestStatus(serverData.status)
            }
          }}
          update={{
            url: AceHighwayAbiInbond7512ApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              manifestData.current = e
              onSetManifestStatus(e.status)
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <AceHWCustomButtonSection
              status={manifestStatus}
              onSaveOnly={() => {
                onSuccessCallback.current = () => {
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
              onSubmitManifest={(data) => {
                manifestData.current = data
                onSubmitManifest()
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage

function AceHWCustomButtonSection(props) {
  const {
    loading,
    onSaveOnly,
    onSubmitManifest,
    onSetLoading  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const history = useHistory()
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)


  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={() => onSubmitManifest(getValues())}
                // type='submit'
                variant='contained'
                size='medium'
                shouldShowProgress={loading}
              >
                Submit
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() =>
          history.push(pathMap.ABI_INBOND_7512_LIST_VIEW)
        }
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
