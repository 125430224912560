import { Box, Card } from '@material-ui/core'
import React from 'react'
import InbondEventAddForm from './InbondEventAddForm'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'

function AddPage({ history, showNotification, onSetLoading }) {
  return (
    <Card>
      <Stepper steps={['Create In-bond Event']} activeStep={0} />
      <Box padding={2}>
        <InbondEventAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)
