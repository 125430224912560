import {
  components,
  constants,
  DataFlattener,
  useServices,
  useTranslation,
  DateTimeFormatter
} from 'cng-web-lib'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AceHighwayAbiInbond7512ApiUrls from 'src/apiUrls/AceHighwayAbiInbond7512ApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import Namespace from 'src/constants/locale/Namespace'
import { getStatusMetadata } from 'src/common/NACommon'
import Table from 'src/components/aciacehighway/Table'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import moment from 'moment-timezone'
import { Chip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CngBackdrop from "src/components/cngcomponents/CngBackDrop";
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { format } from 'date-fns'
import Inbond7512UploadDialog from './Inbond7512UploadDialog'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog,
  CngGridItem
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, location, dataId,  showNotification } = props
  const [lookups, setLookups] = useState(null)
  const [uploadInbond7512Dialog, setUploadInbond7512Dialog] = useState({ open: false, tableRef: null })
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    data: null
  })
 const [loading, setLoading] = useState(false)
  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } =
    useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()

  // useEffect(() => {
  //   Promise.all([
  //     // Status
  //     fetchCodeMaintenanceLookup(
  //       constants.CodeMaintenanceType.CODE_MASTER,
  //       undefined,
  //       [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
  //       undefined,
  //       'code'
  //     ),
      
  //     // First expected port
  //     fetchRecords.execute(
  //       NaCodeMaintenanceApiURLs.GET,
  //       {
  //         filters: [
  //           {
  //             field: 'set_indicator',
  //             operator: EQUAL,
  //             value: 'ACEHWY_POE'
  //           }
  //         ],
  //         customData: { codeMType: 'DocPortCode' }
  //       },
  //       (res) => {
  //         const result = {}

  //         res.content.forEach((item) => {
  //           result[item.code] = item.descriptionEn
  //         })

  //         return result
  //       }
  //     )
  //   ]).then(([status, firstExpectedPort]) => {
  //     setLookups({
  //       status,
  //       firstExpectedPort
  //     })
  //   })
  // }, [])


  function makeTranslatedTextsObject() {
    let abiInbond7512 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ID
    )
    
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CREATED_DATE
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATED_DATE
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SUBMITTED_DATE
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.STATUS
    )
    let actionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ACTION_CODE
    )
    let bondNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.BOND_NUMBER
    )
    let entryType = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ENRTY_TYPE
    )
    let inbondCarrierCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_CODE
    )
    let tripNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.TRIP_NUMBER
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.RESPONSE_DATE
    )
    let editSentRecords = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.EDIT_BUTTON
    )
    let deleteDraftRecords = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.DELETE_DRAFT_RECORDS
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.DELETE_BUTTON
    )
     let cloneButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CLONE_BUTTON
    )
    let printAbiInbond = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.PRINT_ABI_INBOND
    )
    
    return {
      abiInbond7512,
      id,
      createdDate,
      updatedDate,
      submittedDate,
      status,
      actionCode,
      bondNumber,
      entryType,
      inbondCarrierCode,
      tripNumber,
      responseDate,
      editSentRecords,
      editButton,
      deleteDraftRecords,
      deleteButton,
      cloneButton,
      printAbiInbond
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
     {
      field: 'bondNumber',
      sortKey: 'bondNumber',
      title: translatedTextsObject.bondNumber
    },
    {
      field: "entryType",
      sortKey: 'entryType',
      title: translatedTextsObject.entryType
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      }
    },
    {
      field: "inbondCarrierCode",
      sortKey: 'inbondCarrierCode',
      title: translatedTextsObject.inbondCarrierCode
    },
    {
      field: "tripNumber",
      sortKey: 'tripNumber',
      title: translatedTextsObject.tripNumber
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) => data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) => data.submittedDate && data.submittedDate !== '' && data.submittedDate !== 'Invalid date' && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'responseDate',
      sortKey: 'responseDate',
      title: translatedTextsObject.responseDate,
      render: (data) => data.responseDate && data.responseDate !== '' && data.responseDate !== 'Invalid date' && moment(data.responseDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.bondNumber,
      type: 'textfield',
      name: 'bondNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.entryType,
      type: 'textfield',
      name: 'entryType',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted with Warning',
          value: 'accepted_with_warning',
          filterValue: {
            value: '1015'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          fullWidth: true,
          label: 'Preliminary',
          value: 'preliminary',
          filterValue: {
            value: '1009'
          }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          fullWidth: true,
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.inbondCarrierCode,
      type: 'textfield',
      name: 'inbondCarrierCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.tripNumber,
      type: 'textfield',
      name: 'tripNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.responseDate,
      type: 'daterange',
      name: 'responseDate',
      operatorType: BETWEEN
    }
  ]

  function handleCloneAbiInbond(data) {
    createRecord.execute(
      AceHighwayAbiInbond7512ApiUrls.CLONE,
      data,
      (data) => {
        data.arrivalTime = moment(data.arrivalDateTime).tz("Canada/Eastern").format('HH:mm');
        data.arrivalDate = moment(data.arrivalDateTime).tz("Canada/Eastern").format('YYYY-MM-DD');
        history.push(pathMap.ABI_INBOND_7512_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteAbiInbond() {
    if (confirmDialog.data) {
      deleteRecord.execute(
        AceHighwayAbiInbond7512ApiUrls.DELETE,
        confirmDialog.data,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, data: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
  function handlePrintAbiInbond(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AceHighwayAbiInbond7512ApiUrls.PRINT_ABI_INBOND,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ABI_INBOND_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }
  if(loading){
    return(
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
    )
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'upload']} />,
              onClick: () => setUploadInbond7512Dialog({ open: true, tableRef: tableRef })
            },
            label: 'Upload Inbond 7512'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ABI_INBOND_7512_ADD_VIEW)
            },
            label: 'Create Inbond 7512'
          }
        ]}
        columns={columns}
        compact
        fetch={{ url: AceHighwayAbiInbond7512ApiUrls.SEARCH }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
        if(rowData.status === '1008'){
            history.push(`${location.pathname}/view/${rowData.id}`)
        }else{
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
         }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneAbiInbond
          },
          {
            disabled: (rowData) =>
              rowData.status !== '1005' && rowData.status !== '1002' && rowData.status !== '1014',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, data: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005' && rowData.status !== '1002' && rowData.status !== '1014'
                ? translatedTextsObject.deleteDraftRecords
                : null
          },
          {
            label: translatedTextsObject.printAbiInbond,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintAbiInbond
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <Inbond7512UploadDialog
        open={uploadInbond7512Dialog}
        onClose={() => setUploadInbond7512Dialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, data: null })}
        confirmDialog={handleDeleteAbiInbond}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default TablePage