import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import pathMap from 'src/paths/pathMap'
import AceHighwayAbiInbond7512ApiUrls from 'src/apiUrls/AceHighwayAbiInbond7512ApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import Inbond7512ViewContent from './Inbond7512ViewContent'
import ConfirmDialog from '../../common/ConfirmDialog'
import FormProperties from './FormProperties'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN }
} = constants

function ViewPage(props) {
  const { history, location, showNotification } = props
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { id } = useParams()
  const { createRecord, deleteRecord, fetchRecord, fetchRecords } =
    useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)

  const translatedTextsObject = makeTranslatedTextsObject()

  let firstExpectedPort;
  if(location !== undefined) {
    firstExpectedPort = location.firstExpectedPort
  }

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AceHighwayAbiInbond7512ApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Action Code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_ACTION' }],
        undefined,
        'code'
      ),
      // MOT
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_MOT' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value1: 'IFX_US' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // states
      // fetchRecords.execute(
      //   NaCodeMaintenanceApiURLs.GET,
      //   {
      //     customData: { codeMType: 'CountryStateCode' }
      //   },
      //   (res) => res.content
      // )
    ]).then(
      ([
        data,
        status,
        actionCode,
        exportMot,
        portOfArrival,
        states
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ABI_INBOND_7512_LIST_VIEW)
        }
        setData(data)
        setLookups({
          status,
          actionCode,
          exportMot,
          portOfArrival,
          // states
        })
      }
    )
  }, [])

  if (data === null) {
    return <CircularProgress />
  }

  function makeTranslatedTextsObject() {
    let abiInbond7512 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.VERSION
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.PARTY_ID
    )
    let filerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FILER_CODE
    )
    let portCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.PORT_CODE
    )
    let actionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ACTION_CODE
    )
    let bondNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.BOND_NUMBER
    )
    let entryType = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.ENRTY_TYPE
    )
    let inbondCarrierCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_CODE
    )
    let destinationPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.DESTINATION_PORT
    )
    let foreignPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FOREIGN_PORT
    )
    let inbondValue = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_VALUE
    )
    let inbondCarrierId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.INBOND_CARRIER_ID
    )
    let ftzIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.FTZ_INDICATOR
    )
    let btaFdaIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.BTA_FDA_INDICATOR
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.STATUS
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.RESPONSE_DATE
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.DELETE_BUTTON
    )

    return {
      abiInbond7512,
      id,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      partyId,
      filerCode,
      portCode,
      actionCode,
      bondNumber,
      entryType,
      inbondCarrierCode,
      destinationPort,
      foreignPort,
      inbondValue,
      inbondCarrierId,
      ftzIndicator,
      btaFdaIndicator,
      status,
      responseDate,
      editButton,
      cloneButton,
      deleteButton
    }
  }
  
  function handleClone() {
    createRecord.execute(
      AceHighwayAbiInbond7512ApiUrls.CLONE,
      data,
      (data) => {
        history.push(pathMap.ABI_INBOND_7512_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDelete() {
    deleteRecord.execute(
      AceHighwayAbiInbond7512ApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.ABI_INBOND_7512_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.states[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Inbond7512ViewContent
            data={data}
            getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
            id={id}
            translatedTextsObject={translatedTextsObject}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.ABI_INBOND_7512_LIST_VIEW)}
          >
            Back to Inbond 7512 list
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.status === '1005' && (
              <CngButton
                disabled={data.status !== '1005'}
                onClick={() => setConfirmDialogOpen(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              onClick={handleClone}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                disabled={data.status === '1008'}
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.ABI_INBOND_7512}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleDelete}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default ViewPage
