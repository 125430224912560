import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton, CngIconButton }
} = components

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      padding: '12px 16px',
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        cursor: 'pointer',
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function BolContainerTable(props) {
  const {
    bolContainer,
    onViewBolContainer,
    onAddBolContainer,
    onDeleteBolContainer,
    onEditBolContainer
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const classes = useStyles()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()

  const filtered =
    searchTerm !== ''
      ? bolContainer.filter((container) =>
          container.containerNumber.includes(searchTerm)
        )
      : bolContainer

  function makeTranslatedTextsObject() {
    let bolContainer = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.TITLE
    )
    let containerNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_NUMBER
    )
    let sealNumber1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER1
    )
    let sealNumber2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.SEAL_NUMBER2
    )
    let containerDescriptionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.CONTAINER_DESCRIPTION_CODE
    )

    return {
      bolContainer,
      containerNumber,
      sealNumber1,
      sealNumber2,
      containerDescriptionCode
    }
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {bolContainer && bolContainer.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddBolContainer && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddBolContainer}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Container
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.containerNumber}</TableCell>
              <TableCell>{translatedTextsObject.sealNumber1}</TableCell>
              <TableCell>{translatedTextsObject.sealNumber2}</TableCell>
              <TableCell>{translatedTextsObject.containerDescriptionCode}</TableCell>
              {(onEditBolContainer || onDeleteBolContainer) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered && filtered.length > 0 ? (
              filtered.map((bolContainer) => (
                <TableRow
                  key={bolContainer._id || bolContainer.id}
                  hover
                  onClick={() => {
                    if (onViewBolContainer) {
                      onViewBolContainer(bolContainer)
                    }
                  }}
                >
                  <TableCell>{bolContainer.containerNumber}</TableCell>
                  <TableCell>{bolContainer.sealNumber1}</TableCell>
                  <TableCell>{bolContainer.sealNumber2}</TableCell>
                  <TableCell>{bolContainer.containerDescriptionCode}</TableCell>
                  {(onEditBolContainer || onDeleteBolContainer) && (
                    <TableCell>
                      <Grid container spacing={1} justify='flex-end'>
                        {onEditBolContainer && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onEditBolContainer(bolContainer)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteBolContainer && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteBolContainer(bolContainer)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    3 + (onEditBolContainer || onDeleteBolContainer ? 1 : 0)
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default BolContainerTable
