import { Box, Divider, Grid, IconButton, InputAdornment, Typography, useTheme } from '@material-ui/core'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { NaAceHwyPOLPortAutocompleteField, NaAceStateCodeAutoCompleteField } from 'src/components/na'
import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import { useFormContext, useWatch } from 'react-hook-form'

import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import CngSection from 'src/components/cngcomponents/CngSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaAcePartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { NaPartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import Namespace from 'src/constants/locale/Namespace'
import PartyMasterApiUrls from '../../../apiUrls/PartyMasterApiUrls'
import Switch from 'src/components/Switch'
import TemplateSelectDialog from 'src/components/aciacehighway/TemplateSelectDialog'
import _ from 'lodash'
import makeValidationSchema from './HazmatInfoMakeValidationSchema'
import CollapsibleSection from '../../../components/ui/CollapsibleSection';

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngSelectField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    }
  },
  CngGridItem,
  table: { useFetchCodeMaintenanceLookup },
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  hazardousMaterialCode: '',
  hazardousMaterialClass: '',
  hazardousMaterialCodeQual: '',
  hazardousMaterialDescription: '',
  hazardousMaterialContact: '',
  flashpointTemperature: '',
  uomCode: 'CE',
  negativeIndicator: '',
  hazardousMaterialDescription1:'',
  hazardousMaterialClassification1:'',
  hazardousMaterialDescription2:'',
  hazardousMaterialClassification2:''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  showNotification
}) {
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { getValues, setValue, reset, watch, trigger } = useFormContext()

  const theme = useTheme()

  const otherMaterialDescClass = watch([
    'hazardousMaterialDescription1',
    'hazardousMaterialClassification1',
    'hazardousMaterialDescription2',
    'hazardousMaterialClassification2'
  ])

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let hazmatInformation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.TITLE
    )
    let hazardousMaterialCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CODE
    )
    let hazardousMaterialClass = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CLASS
    )
    let hazardousMaterialCodeQual = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CODE_QUAL
    )
    let hazardousMaterialDescription = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_DESCRIPTION
    )
    let hazardousMaterialContact = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CONTACT
    )
    let flashpointTemperature = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.FLASHPOINT_TEMPERATURE
    )
    let uomCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.UOM_CODE
    )
    let negativeIndicator = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.NEGATIVE_INDICATOR
    )
    let hazardousMaterialDescription1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_DESCRIPTION1
    )
    let hazardousMaterialClassification1 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CLASSIFICATION1
    )
    let hazardousMaterialDescription2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_DESCRIPTION2
    )
    let hazardousMaterialClassification2 = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.BolContainer.HazmatInformation.HAZARDOURS_MATERIAL_CLASSIFICATION2
    )

    return {
      hazmatInformation,
      hazardousMaterialCode,
      hazardousMaterialClass,
      hazardousMaterialCodeQual,
      hazardousMaterialDescription,
      hazardousMaterialContact,
      flashpointTemperature,
      uomCode,
      negativeIndicator,
      hazardousMaterialDescription1,
      hazardousMaterialClassification1,
      hazardousMaterialDescription2,
      hazardousMaterialClassification2
    }
  }

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2}>
          <CngGridItem xs={12} md={6} lg={6}>
            <CngTextField
              required
              name='hazardousMaterialCode'
              label={translatedTextsObject.hazardousMaterialCode}
              onChange={(e) => {
                setValue('hazardousMaterialCode', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('hazardousMaterialCode', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              size='small'
              inputProps={{ maxLength: 10 }}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={6} lg={6}>
            <CngTextField
              name='hazardousMaterialClass'
              label={translatedTextsObject.hazardousMaterialClass}
              onChange={(e) => {
                setValue('hazardousMaterialClass', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('hazardousMaterialClass', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              size='small'
              inputProps={{ maxLength: 4 }}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={6} lg={6}>
            <CngCodeMasterAutocompleteField
                name='hazardousMaterialCodeQual'
                label={translatedTextsObject.hazardousMaterialCodeQual}
                codeType='ACE_HW_ABI_HAZMAT_MAT'
                size='small'
              />
          </CngGridItem>
          <CngGridItem xs={12} md={6} lg={6}>
            <CngTextField
              name='hazardousMaterialDescription'
              label={translatedTextsObject.hazardousMaterialDescription}
              onChange={(e) => {
                setValue('hazardousMaterialDescription', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('hazardousMaterialDescription', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              size='small'
              inputProps={{ maxLength: 30 }}
            />
          </CngGridItem>
          <CngGridItem xs={12} md={6} lg={6}>
            <CngTextField
              name='hazardousMaterialContact'
              label={translatedTextsObject.hazardousMaterialContact}
              onChange={(e) => {
                setValue('hazardousMaterialContact', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('hazardousMaterialContact', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              size='small'
              inputProps={{ maxLength: 24 }}
            />
          </CngGridItem>
          <CngGridItem lg={2}>
            <CngSelectField
              required={getValues("flashpointTemperature")}
              name="negativeIndicator"
              label={'Flashpoint Indicator'}
              items={[{ text: "+", value: "Y" },{ text: "-", value: "N" }]}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} md={4} lg={4}>
            <CngTextField
              name='flashpointTemperature'
              label={translatedTextsObject.flashpointTemperature +" (CE)"}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('flashpointTemperature', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
              size='small'
              inputProps={{ maxLength: 3 }}
            />
          </CngGridItem>
          <Grid item xs={12}>
            <CollapsibleSection
              defaultExpanded={otherMaterialDescClass.filter(other => other).length > 0}
              title={"HAZMAT Other Description and Classification"}
            >
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={6}>
                  <CngTextField
                    name='hazardousMaterialDescription1'
                    label={translatedTextsObject.hazardousMaterialDescription1}
                    size='small'
                    inputProps={{ maxLength: 29 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                  <CngTextField
                    name='hazardousMaterialClassification1'
                    label={translatedTextsObject.hazardousMaterialClassification1}
                    size='small'
                    inputProps={{ maxLength: 30 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                  <CngTextField
                    name='hazardousMaterialDescription2'
                    label={translatedTextsObject.hazardousMaterialDescription2}
                    size='small'
                    inputProps={{ maxLength: 29 }}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={6}>
                  <CngTextField
                    name='hazardousMaterialClassification2'
                    label={translatedTextsObject.hazardousMaterialClassification2}
                    size='small'
                    inputProps={{ maxLength: 30 }}
                  />
                </CngGridItem>
              </Grid>
            </CollapsibleSection>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const HazmatInfoFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default HazmatInfoFormProperties
