import { Box, Card, useTheme } from '@material-ui/core'
import React from 'react'
import Inbond7512AddForm from './Inbond7512AddForm'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'

function AddPage({ history, showNotification, onSetLoading }) {
  const theme = useTheme()

  return (
    <Card>
      <Stepper steps={['Create In-bond 7512']} activeStep={0} />
      <Box
        padding={2}
        style={{ backgroundColor: theme.palette.background.sectionOddBg }}
      >
        <Inbond7512AddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => console.log('submit success')}
          onSetLoading={onSetLoading}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)
