import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbondEventKeys from 'src/constants/locale/key/AceHighwayAbiInbondEvent'
import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  DataFlattener,
  useServices,
  useTranslation
} from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    data,
    status,
    onSetLoading,
    onSubmit,
    showNotification,
    onSetStatus,
    history, 
  } = props
  const { id } = useParams()
  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.ABI_INBOND_EVENT}/edit/` + e.id)
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AceHwyInbondArrivalApiUrls.GET,
            onPreSuccess: (serverData) => {
              data.current = serverData
              onSetStatus(serverData.status)
            }
          }}
          update={{
            url: AceHwyInbondArrivalApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              data.current = e
              onSetStatus(e.status)
              onSuccessCallback.current()
            }
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <AceHWCustomButtonSection
              status={status}
              onSaveOnly={() => {
                onSuccessCallback.current = () => {
                  onSetLoading(false)
                }
              }}
              loading={loading}
              onSetLoading={onSetLoading}
              onSubmit={(updatedData) => {
                data.current = updatedData
                onSubmit()
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage

function AceHWCustomButtonSection(props) {
  const {
    loading,
    onSaveOnly,
    onSubmit,
    onSetLoading
  } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const history = useHistory()
  const { getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.SUBMIT_SUCCESS_MSG
    )

    return { submitSuccessMsg }
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveOnly}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={() => onSubmit(getValues())}
                // type='submit'
                variant='contained'
                size='medium'
                shouldShowProgress={loading}
              >
                Submit
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() =>
          history.push(pathMap.ACE_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
        }
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
