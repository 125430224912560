import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbondEventKeys from 'src/constants/locale/key/AceHighwayAbiInbondEvent'
import moment from 'moment'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
    AceHighwayAbiInbondEventKeys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
    AceHighwayAbiInbondEventKeys.Validations.MAX_LENGTH
  )
  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
    AceHighwayAbiInbondEventKeys.Validations.NUMERIC
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
    AceHighwayAbiInbondEventKeys.Validations.ALPHANUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
    AceHighwayAbiInbondEventKeys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )
  let arrivalDateTimeFormat = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
    AceHighwayAbiInbondEventKeys.Validations.INVALID_ARRIVAL_DATE_TIME_FORMAT
  )

  let regexIRS= "^\\d{2}\\-\\d{9}$"
  let regexSSN= "^\\d{6}\\-\\d{5}$"
  let regexUSCBP= "^\\d{6}\\-\\d{5}$"
  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  let regexDate= "[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])"
  let regexTime= "(2[0-3]|[01][0-9]):[0-5][0-9]"

  return Yup.object({
    filerCode: Yup.string().nullable(),
    portCode: Yup.string().nullable(),
    msgFunction: Yup.string().required(requiredMessage).nullable(),
    inbond7512No: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12").when('msgFunction', {
      is: (msgFunction) => (msgFunction !== '2' || msgFunction !== '6'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    masterBolIssuerCode: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).min(4, minLengthErrorMessage + " 4").max(16, maxLengthErrorMessage + " 16").when('msgFunction', {
      is: (msgFunction) => (msgFunction === '2' || msgFunction === '3' || msgFunction === '6' || msgFunction === '7'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    houseBol: Yup.string().matches(regexAlphaNumeric, alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12").nullable(),
    firmLocation: Yup.string().when(['exportMot', 'msgFunction'], {
      is: (exportMot, msgFunction) => ((exportMot !== '40' && exportMot !== '41') && (msgFunction === '1' || msgFunction === '2' || msgFunction === '3')),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    containerNumber: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(14, maxLengthErrorMessage + " 14").when(['exportMot','msgFunction'], {
      is: (exportMot, msgFunction) => (exportMot !== '40' && msgFunction === '3' || msgFunction === '7'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    arrivalDate: Yup.string().required(requiredMessage).matches(regexDate,arrivalDateTimeFormat).nullable(),
    arrivalTime: Yup.string().required(requiredMessage).matches(regexTime,arrivalDateTimeFormat).nullable(),
    inbondDestination: Yup.string().when('msgFunction', {
      is: (msgFunction) => (msgFunction === '1' || msgFunction === '2' || msgFunction === '3' || msgFunction === 'Z'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    inbondCarrierCode: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(4, maxLengthErrorMessage + " 4").when('msgFunction', {
      is: (msgFunction) => (msgFunction === 'A'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    bondedCarrierId: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericErrorMessage).max(12,maxLengthErrorMessage + " 12").when('msgFunction', {
      is: (msgFunction) => (msgFunction === 'A' || msgFunction === 'Z'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    status: Yup.string().nullable(),
    // responseDate: Yup.date().nullable(),
    city: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(19, maxLengthErrorMessage + " 19").when('msgFunction', {
      is: (msgFunction) => (msgFunction === 'A'),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
    stateCode: Yup.string().when('city', {
      is: (city) => (city),
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    exportMot: Yup.string().test("exportMot",requiredMessage,function(){
      let exportConveyance = this.parent["exportConveyance"];
      let msgFunction = this.parent["msgFunction"];
      let exportMot = this.parent["exportMot"];
      if((null === exportMot || undefined === exportMot) && (null !== exportConveyance && undefined !== exportConveyance) && (msgFunction === '5' || msgFunction === '6' || msgFunction === '7'))
        return false;
      else
        return true;
    }).nullable(),
    exportConveyance: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(23, maxLengthErrorMessage + " 23").when(['msgFunction', 'exportMot'], {
      is: (msgFunction, exportMot) => (exportMot && (msgFunction === '5' || msgFunction === '6' || msgFunction === '7')),
      then: Yup.string().required(requiredMessage),
    }).nullable(),
  })
}

export default makeValidationSchema
