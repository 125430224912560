import AceHighwayAbiInbond7512ApiUrls from 'src/apiUrls/AceHighwayAbiInbond7512ApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { DataFlattener, components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from 'react-router-dom'
import ConfirmDialog from '../../common/ConfirmDialog'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, showNotification, onSetLoading }) {
  const loc = useLocation()
  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.ABI_INBOND_7512}/edit/` + e.id)
  )
  let formikPropsData
  if (loc.state !== undefined) {
    formikPropsData = {
      ...FormProperties.formikProps,
      // initialValues: DataFlattener.parse(loc.state)
      initialValues: loc.state
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
        <FormProperties.Fields
          disabled={false}
          showNotification={showNotification}
          shouldHideMap={shouldHideMap}
        />
      )}
      formikProps={formikPropsData}
      toClientDataFormat={FormProperties.toClientDataFormat}
      toServerDataFormat={FormProperties.toServerDataFormat}
      create={{
        url: AceHighwayAbiInbond7512ApiUrls.POST,
        onSuccess: (e) => {
          showNotification('success', 'Created successfully.')
          history.push({pathname: `${pathMap.ABI_INBOND_7512}/edit/`+e.id})
          onSetLoading(false)
        }
      }}
      renderButtonSection={(loading) => (
        <AceHWCustomButtonSection
          history={history}
          loading={loading}
          onSetLoading={onSetLoading}
        />
      )}
    />
  )
}

export default AddPage

function AceHWCustomButtonSection(props) {
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { history, loading, onSetLoading } = props

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton
            color='secondary'
            disabled={loading}
            onClick={() => setConfirmDialog(true)}
            size='medium'
          >
            Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              disabled={loading}
              // onClick={onSaveOnly}
              type='submit'
              size='medium'
              shouldShowProgress={loading}
            >
              Save
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <ConfirmDialog
      isConfirmDialogOpen={confirmDialog}
      closeDialog={() => setConfirmDialog(false)}
      confirmDialog={() =>
        history.push(pathMap.ABI_INBOND_7512_LIST_VIEW)
      }
      content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
      okMsg='Yes, discard'
      cancelMsg='Continue editing'
      title='Discard'
    />
    </>
  )
}
