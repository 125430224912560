import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { components, constants, useServices, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbondEventKeys from 'src/constants/locale/key/AceHighwayAbiInbondEvent'
import pathMap from 'src/paths/pathMap'
import AceHwyInbondArrivalApiUrls from 'src/apiUrls/AceHwyInbondArrivalApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import InbondEventViewContent from './InbondEventViewContent'
import ConfirmDialog from '../../common/ConfirmDialog'
import FormProperties from './FormProperties'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, IN }
} = constants

function ViewPage(props) {
  const { history, location, showNotification } = props
  const [data, setData] = useState(null)
  const [lookups, setLookups] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const { id } = useParams()
  const { createRecord, deleteRecord, fetchRecord, fetchRecords } =
    useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT)

  const translatedTextsObject = makeTranslatedTextsObject()

  let firstExpectedPort;
  if(location !== undefined) {
    firstExpectedPort = location.firstExpectedPort
  }

  useEffect(() => {
    Promise.all([
      // Data
      fetchRecord.execute(
        AceHwyInbondArrivalApiUrls.GET,
        id,
        (res) => res.content[0]
      ),
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Action Code
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_ACTION' }],
        undefined,
        'code'
      ),
      // MOT
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_ABI_EXPORT_MOT' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'set_indicator', operator: EQUAL, value1: 'IFX_US' }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      )
    ]).then(
      ([
        data,
        status,
        actionCode,
        exportMot,
        portOfArrival,
        states
      ]) => {
        if (data === undefined) {
          showNotification('error', 'Data not found.')
          history.push(pathMap.ABI_INBOND_EVENT_LIST_VIEW)
        }
        setData(data)
        setLookups({
          status,
          actionCode,
          exportMot,
          portOfArrival,
          states
        })
      }
    )
  }, [])

  if (data === null) {
    return <CircularProgress />
  }

  function makeTranslatedTextsObject() {
    let aceHighwayAbiInbond = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ID
    )
    let createdBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.UPDATED_DATE
    )
    let submittedBy = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.SUBMITTED_DATE
    )
    let version = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.VERSION
    )
    let partyId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.PARTY_ID
    )
    let filerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.FILER_CODE
    )
    let portCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.PORT_CODE
    )
    let actionCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ACTION_CODE
    )
    let inbondNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.INBOND_NUMBER
    )
    let masterBolIssuerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.MASTER_BOL_ISSUER_CODE
    )
    let houseBol = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.HOUSE_BOL
    )
    let firmLocation = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.FIRM_LOCATION
    )
    let containerNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CONTAINER_NUMBER
    )
    let arrivalDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_DATE
    )
    let arrivalTime = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_TIME
    )
    let arrivalPort = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.ARRIVAL_PORT
    )
    let inbondCarrierCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.INBOND_CARRIER_CODE
    )
    let bondedCarrierId = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.BONDED_CARRIER_ID
    )
    let city = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CITY
    )
    let stateCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.STATE_CODE
    )
    let exportMot = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.EXPORT_MOT
    )
    let exportConveyance = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.EXPORT_CONVEYANCE
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.STATUS
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.RESPONSE_DATE
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_EVENT,
      AceHighwayAbiInbondEventKeys.DELETE_BUTTON
    )

    return {
      aceHighwayAbiInbond,
      id,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      submittedBy,
      submittedDate,
      version,
      partyId,
      filerCode,
      portCode,
      actionCode,
      inbondNumber,
      masterBolIssuerCode,
      houseBol,
      firmLocation,
      containerNumber,
      arrivalDate,
      arrivalTime,
      arrivalPort,
      inbondCarrierCode,
      bondedCarrierId,
      city,
      stateCode,
      exportMot,
      exportConveyance,
      status,
      responseDate,
      editButton,
      cloneButton,
      deleteButton
    }
  }
  
  function handleClone() {
    createRecord.execute(
      AceHwyInbondArrivalApiUrls.CLONE,
      data,
      (data) => {
        history.push(pathMap.ABI_INBOND_EVENT_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDelete() {
    deleteRecord.execute(
      AceHwyInbondArrivalApiUrls.DELETE,
      data,
      () => {
        showNotification('success', 'Delete succeeded')
        history.push(pathMap.ABI_INBOND_EVENT_LIST_VIEW)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.states[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <InbondEventViewContent
            data={data}
            getCountryStateLabel={getCountryStateLabel}
            getLookupValue={getLookupValue}
            id={id}
            translatedTextsObject={translatedTextsObject}
          />
        </CardContent>
        <Divider />
        <CardActions style={{ padding: 16, justifyContent: 'space-between' }}>
          <CngButton
            color='secondary'
            onClick={() => history.push(pathMap.ABI_INBOND_EVENT_LIST_VIEW)}
          >
            Back to Inbond Arrival/Export List
          </CngButton>
          <Box display='flex' style={{ gap: 16 }}>
            {data.status === '1005' && (
              <CngButton
                disabled={data.status !== '1005'}
                onClick={() => setConfirmDialogOpen(true)}
                startIcon={
                  <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
                }
                variant='outlined'
              >
                {translatedTextsObject.deleteButton}
              </CngButton>
            )}
            <CngButton
              onClick={handleClone}
              startIcon={<FontAwesomeIcon icon={['fal', 'copy']} size='sm' />}
              variant='outlined'
            >
              {translatedTextsObject.cloneButton}
            </CngButton>
            {data.status !== '1008' && (
              <CngButton
                color='primary'
                disabled={data.status === '1008'}
                startIcon={<FontAwesomeIcon icon={['fal', 'pen']} size='sm' />}
                onClick={() =>
                  history.push(`${pathMap.ABI_INBOND_EVENT}/edit/${id}`)
                }
              >
                {translatedTextsObject.editButton}
              </CngButton>
            )}
          </Box>
        </CardActions>
      </Card>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={handleDelete}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default ViewPage
