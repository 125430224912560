import React, { useEffect, useState } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL }
} = constants

function BillOfLadingTable(props) {
  const {
    data,
    onAddBol,
    onDeleteBol,
    onEditBol
  } = props

  const [lookups, setLookups] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const classes = useStyles()

  const filtered =
    searchTerm !== ''
      ? data.filter((bol) =>
          bol.masterBillNumber.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : data

  // useEffect(() => {
  //   async function fetchData() {
  //     const data = await fetchCodeMaintenanceLookup(
  //       constants.CodeMaintenanceType.CODE_MASTER,
  //       undefined,
  //       [
  //         { field: 'codeType', operator: EQUAL, value: 'ACE_HW_EQUIPMENT_TYPE' }
  //       ],
  //       undefined,
  //       'code'
  //     )

  //     setLookups({ equipmentType: data })
  //   }

  //   fetchData()
  // }, [])

  function makeTranslatedTextsObject() {
    let masterBolIssuerCode = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.MASTER_BOL_ISSUER_CODE
    )
    let masterBillNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.MASTER_BILL_NUMBER
    )
    let houseBillNumber = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.HOUSE_BILL_NUMBER
    )
    let inbondQuantity = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.INBOND_QUANTITY
    )

    return { masterBolIssuerCode, masterBillNumber, houseBillNumber, inbondQuantity }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {data.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddBol && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddBol}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add Bill Of Lading
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translatedTextsObject.masterBolIssuerCode}</TableCell>
              <TableCell>{translatedTextsObject.masterBillNumber}</TableCell>
              <TableCell>{translatedTextsObject.houseBillNumber}</TableCell>
              <TableCell>{translatedTextsObject.inbondQuantity}</TableCell>
              {(onEditBol || onDeleteBol) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((bol, index) => (
                <TableRow key={bol._id || bol.id || index}>
                  <TableCell>{bol.masterBolIssuerCode}</TableCell>
                  <TableCell>{bol.masterBillNumber}</TableCell>
                  <TableCell>{bol.houseBillNumber}</TableCell>
                  <TableCell>{bol.inbondQuantity}</TableCell>
                  {(onEditBol || onDeleteBol) && (
                    <TableCell>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-end'
                        style={{ gap: 8 }}
                      >
                        {onEditBol && (
                          <CngIconButton
                            type='outlined'
                            icon={['fal', 'pen']}
                            onClick={() => onEditBol(bol)}
                            size='small'
                          />
                        )}
                        {onDeleteBol && (
                          <CngIconButton
                            type='outlined'
                            icon={['fal', 'trash']}
                            onClick={() => onDeleteBol(bol)}
                            size='small'
                          />
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={
                    3 + (onEditBol || onDeleteBol ? 1 : 0)
                  }
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default BillOfLadingTable
