import React, { useRef } from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import BillOfLadingFormProperties from './BillOfLadingFormProperties'
import { useFormContext } from 'react-hook-form'
import Box from '@material-ui/core/Box'

const {
  button: { CngPrimaryButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function BillOfLadingDialog(props) {
  const { open, onClose, bol, ftz, mot, showNotification } = props
  const { initialValues, makeValidationSchema } =
    BillOfLadingFormProperties.formikProps

  const lastIndex = useRef(0)
  const {
    getValues,
    setValue,
    formState: { isSubmitting }
  } = useFormContext()

  const { translate } = useTranslation(Namespace.ACE_HIGHWAY_ABI_INBOND_7512)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let bolTitle = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.Bol.TITLE
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
      AceHighwayAbiInbond7512Keys.UPDATE_BUTTON
    )

    return { bolTitle, saveButton, updateButton }
  }

  function handleAddBol(data) {
    const bol = [...getValues('bol'), data].map(
      (b) => ({ ...b, _id: ++lastIndex.current })
    )

    setValue('bol', bol)
    onClose()
  }

  function handleEditBol(data) {
    const bol = [...getValues('bol')]
    const index = bol.findIndex((b) =>
      b.id ? b.id === data.id : b._id === data._id
    )

    bol[index] = data

    setValue('bol', bol, { shouldValidate: true })
    onClose()
  }

  return (
    <CngDialog
      dialogContent={
        <CngForm
          fieldLevel='form'
          formikProps={{
            initialValues: bol || initialValues,
            makeValidationSchema: makeValidationSchema,
            onSubmit: (data) => {
              if (bol) {
                handleEditBol(data)
              } else {
                handleAddBol(data)
              }
            }
          }}
          formState={FormState.COMPLETED}
          innerForm={true}
          renderBodySection={(labelMap, shouldHideMap) => (
            <BillOfLadingFormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              ftz={ftz}
              mot={mot}
            />
          )}
          renderButtonSection={() => (
            <Box display='flex' justifyContent='flex-end'>
              <CngPrimaryButton type='submit' disabled={isSubmitting}>
                {bol
                  ? translatedTextsObject.updateButton
                  : translatedTextsObject.saveButton}
              </CngPrimaryButton>
            </Box>
          )}
        />
      }
      dialogTitle={translatedTextsObject.bolTitle}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default BillOfLadingDialog
